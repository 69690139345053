import DownloadIcon from "@mui/icons-material/Download";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { Button, Link as MuiLink } from "@mui/material";

// Note that we use component={MuiLink} here to make the download action work properly; the
// react-router-dom Link is for navigation.
const AudienceCsvExportDownloadButton = ({ disabled = false, url }) => (
  <Button
    download
    component={MuiLink}
    disabled={disabled}
    href={url}
    startIcon={url ? <DownloadIcon /> : <DownloadingIcon />}
  >
    Download export
  </Button>
);

export default AudienceCsvExportDownloadButton;
