import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { Link } from "react-router-dom";

import { COLORS } from "../../colors";

const StyledMenu = styled(Menu)`
  z-index: 999;
  & .MuiPaper-root {
    background: ${COLORS.lightGray};
    border-radius: 18px;
    padding-top: 15px;
    margin-top: -18px;
  }

  & .MuiSvgIcon-root {
    margin-right: 5px;
  }
`;

const DropdownButton = ({ actions, text, icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        color="secondary"
        onClick={handleClick}
        startIcon={icon}
        sx={{ zIndex: 1000 }}
      >
        {text}
      </Button>
      <StyledMenu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {actions.map(action => (
          <MenuItem
            component={Link}
            disabled={action.comingSoon}
            key={`${action.name}-${action.url}`}
            to={action.url}
          >
            {action.icon} {action.name} {action.comingSoon ? "(coming soon)" : ""}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};
export default DropdownButton;
