import { gql, useMutation } from "@apollo/client";

import { useAppStateDispatch } from "../AppStateContext";
import {
  CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS,
  CORE_GENERIC_CONTENT_VARIATION_FIELDS,
} from "../fragments";

import useGetActiveOrg from "./useGetActiveOrg";

const useCreateContentVariation = type => {
  const { organization } = useGetActiveOrg();
  const dispatch = useAppStateDispatch();

  const mutationName = `createContentVariation${type}`;
  const CREATE_CONTENT_VARIATION = gql`
    ${CORE_GENERIC_CONTENT_VARIATION_FIELDS}
    mutation ($input: ContentVariation${type}Input!) {
      ${mutationName}(input: $input) {
        ...CoreGenericContentVariationFields
        ${CONTENT_VARIATION_TYPE_SPECIFIC_FIELD_STRINGS[type]}
      }
    }
  `;

  const [doCreateContentVariationMutation, { loading: createContentVariationIsLoading }] =
    useMutation(CREATE_CONTENT_VARIATION);

  const createContentVariation = ({
    contentVariationData,
    contentGroupRawId,
    onCreate,
    onError,
  }) => {
    const input = {
      name: contentVariationData.name,
      organizationId: organization.id,
      contentGroupId: contentGroupRawId,
    };

    if (type === "DirectMail") {
      input.contentComponentPdfId = contentVariationData.contentComponentPdfId;
    } else {
      input.text = { content: contentVariationData.textContent };
      input.contentComponentImageId = contentVariationData.contentComponentImageId;
    }

    doCreateContentVariationMutation({
      variables: { input },
      onCompleted: variationData => {
        const contentGroupTypedId = `${type}-${contentGroupRawId}`;
        const variation = variationData[mutationName];
        dispatch({
          type: "org-add-variation-to-content-group",
          variation,
          contentGroupTypedId,
          organizationId: organization.id,
        });
        // Note that when onCreate is executed, we may not have dispatched the new data yet.
        if (onCreate) {
          onCreate();
        }
      },
      onError: () => onError && onError(),
    });
  };

  return { createContentVariation, createContentVariationIsLoading };
};

export default useCreateContentVariation;
