import { gql, useMutation } from "@apollo/client";
import {
  Button,
  FormControlLabel,
  Switch,
  Grid,
  Slider,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAppStateDispatch } from "../../AppStateContext";
import { COLORS } from "../../colors";
import { VALID_AUDIENCES_FOR_LOOKALIKES_COPY } from "../../constants";
import { CORE_AUDIENCE_FIELDS } from "../../fragments";
import { userFriendlyNumber } from "../../helpers";
import useAudienceFiltersSummaries from "../../hooks/useAudienceFiltersSummaries";
import useAudienceNameField from "../../hooks/useAudienceNameField";
import useFormErrors from "../../hooks/useFormErrors";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useGetAudienceFromParam from "../../hooks/useGetAudienceFromParam";
import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import useGetCurrentVersionOfAudienceFilteredFrom from "../../hooks/useGetCurrentVersionOfAudienceFilteredFrom";
import useGetDefaultFilters from "../../hooks/useGetDefaultFilters";
import useMaintainCurrentAudienceId from "../../hooks/useMaintainCurrentAudienceId";
import useTransformFiltersForBackendFn from "../../hooks/useTransformFiltersForBackendFn";
import useTransformedFiltersFromBackend from "../../hooks/useTransformedFiltersFromBackend";
import InfoBox from "../shared/InfoBox";
import Loading from "../shared/Loading";

import AudienceErrorInfoBox from "./AudienceErrorInfoBox";
import AudienceFilters from "./AudienceFilters";
import SelectAudience from "./SelectAudience";

const CREATE_LOOKALIKE_AUDIENCE = gql`
  ${CORE_AUDIENCE_FIELDS}
  mutation ($input: AudienceLookalikeInput!) {
    createLookalikeAudience(input: $input) {
      ...CoreAudienceFields
    }
  }
`;

// keep *NSYNC with MIN_AUDIENCE_SIZE_FOR_LOOKALIKE on server side
const NUM_LOOKALIKES_MIN = 1000;
const NUM_LOOKALIKES_MAX = 5000000;
const NUM_LOOKALIKES_STEP = 50000;
const NUM_LOOKALIKES_DEFAULT = 100000;

const CreateLookalike = () => {
  const [doCreateLookalikeMutation, { loading: createLookalikeLoading }] =
    useMutation(CREATE_LOOKALIKE_AUDIENCE);
  const { organization } = useGetActiveOrg();
  const dispatch = useAppStateDispatch();
  const navigate = useNavigate();
  const { audiences } = organization;
  const existingAudience = useGetAudienceFromParam();
  const DEFAULT_FILTERS = useGetDefaultFilters();
  const { formErrors, updateFormError } = useFormErrors();
  const { audienceNameField, audienceName, validateAudienceName, setAudienceName } =
    useAudienceNameField();
  const getCurrentVersionOfAudienceFilteredFrom = useGetCurrentVersionOfAudienceFilteredFrom();

  const additionalAudiencesFilter = audience => {
    // I.e. CSV audience, so you can create a lookalike directly from it.
    if (audience.canCreateLookalikeFrom) {
      return true;
    }
    // Audience created by applying filters to a CSV audience. In this case we'll pre-fill filters.
    if (audience.audienceFilteredFrom && audience.audienceFilteredFrom.canCreateLookalikeFrom) {
      return true;
    }
    // Same as the above, but the audience this audience applied filters to is no longer the
    // current version of that audience -- so we need to use the current version of it.
    const currentVersionOfAudienceFilteredFrom = getCurrentVersionOfAudienceFilteredFrom(audience);
    if (
      currentVersionOfAudienceFilteredFrom &&
      currentVersionOfAudienceFilteredFrom.canCreateLookalikeFrom
    ) {
      return true;
    }
  };

  const audiencesReadyforLookalikeCreation =
    useGetCurrentAudiences().filter(additionalAudiencesFilter);

  const [newAudienceId, setNewAudienceId] = useState();
  useMaintainCurrentAudienceId(newAudienceId);

  const [lookalikeSize, setLookalikeSize] = useState(NUM_LOOKALIKES_DEFAULT);
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const [includeOriginalAudience, setIncludeOriginalAudience] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(existingAudience);
  const [audienceFilters, setAudienceFilters] = useState(DEFAULT_FILTERS);
  const transformFiltersForBackend = useTransformFiltersForBackendFn();
  const { filtersSummaries } = useAudienceFiltersSummaries(
    selectedAudience && selectedAudience.filters,
  );

  const newAudience = audiences && audiences.find(a => a.id === newAudienceId);
  const newAudienceIsActive = newAudience && newAudience.status === "ACTIVE";
  const newAudienceIsPending = newAudience && newAudience.status === "PENDING";
  const newAudienceIsError = newAudience && newAudience.status === "ERROR";

  // If this audience was filtered from a CSV audience that is eligible to create lookalikes from,
  // that's our base audience.
  const csvAudienceFilteredFrom = useMemo(() => {
    if (selectedAudience && selectedAudience.audienceFilteredFrom) {
      const audienceFilteredFrom = audiences.find(
        a => a.id === selectedAudience.audienceFilteredFrom.id,
      );
      if (audienceFilteredFrom.canCreateLookalikeFrom) {
        return audienceFilteredFrom;
      }
    }
  }, [selectedAudience, audiences]);
  const currentVersionOfCsvAudienceFilteredFrom = useMemo(() => {
    if (selectedAudience && selectedAudience.audienceFilteredFrom && !csvAudienceFilteredFrom) {
      // If there isn't a current version or there isn't an audienceFilteredFrom etc. this will
      // return null which is what we want here, i.e. we don't need another condition.
      return getCurrentVersionOfAudienceFilteredFrom(selectedAudience);
    }
  }, [selectedAudience, getCurrentVersionOfAudienceFilteredFrom, csvAudienceFilteredFrom]);
  const isFilteredFromCsv = !!(csvAudienceFilteredFrom || currentVersionOfCsvAudienceFilteredFrom);
  const audienceToCreateFrom =
    csvAudienceFilteredFrom || currentVersionOfCsvAudienceFilteredFrom || selectedAudience;

  useEffect(() => {
    setAudienceName(selectedAudience ? `Lookalike of ${audienceToCreateFrom.name}` : "");
    navigate(`/audiences/expand/${selectedAudience ? selectedAudience.id : ""}`);
  }, [selectedAudience, setAudienceName, navigate, audienceToCreateFrom]);

  useEffect(() => {
    if (newAudienceIsActive) {
      navigate(`/audiences/${newAudienceId}`);
    }
  }, [newAudienceId, newAudienceIsActive, navigate]);

  let filtersToStartFrom;
  if (isFilteredFromCsv) {
    filtersToStartFrom = selectedAudience.filters;
  }

  const transformedFiltersToStartFrom = useTransformedFiltersFromBackend(filtersToStartFrom);

  // If we're starting from an existing set of filters, set state to that.
  useEffect(() => {
    setAudienceFilters(transformedFiltersToStartFrom || DEFAULT_FILTERS);
    setShouldShowFilters(!!isFilteredFromCsv);
  }, [transformedFiltersToStartFrom, DEFAULT_FILTERS, setAudienceFilters, isFilteredFromCsv]);

  const handleLookalikeSizeInputBlur = e => {
    if (e.target.value < NUM_LOOKALIKES_MIN) {
      setLookalikeSize(NUM_LOOKALIKES_MIN);
    } else if (e.target.value > NUM_LOOKALIKES_MAX) {
      setLookalikeSize(NUM_LOOKALIKES_MAX);
    }
  };

  const handleCreateLookalike = () => {
    if (!validateAudienceName()) {
      return;
    }
    const transformedFilters = shouldShowFilters
      ? transformFiltersForBackend(audienceFilters)
      : null;
    doCreateLookalikeMutation({
      variables: {
        input: {
          pk: audienceToCreateFrom.id,
          size: lookalikeSize,
          name: audienceName,
          filters: transformedFilters,
          includeOriginalAudience,
        },
      },
      onCompleted: data => {
        if (data.createLookalikeAudience.id) {
          dispatch({
            type: "org-add-or-update-audience",
            organizationId: data.createLookalikeAudience.organization.id,
            audience: data.createLookalikeAudience,
          });
          setNewAudienceId(data.createLookalikeAudience.id);
        }
      },
    });
  };

  const createAudienceButton = (
    <Box>
      <Button disabled={Object.keys(formErrors).length > 0} onClick={handleCreateLookalike}>
        Create audience
      </Button>
    </Box>
  );
  let filtersAndOrCreateAudienceButton;
  if (shouldShowFilters && selectedAudience) {
    filtersAndOrCreateAudienceButton = (
      <>
        <AudienceFilters
          {...{ audienceFilters, setAudienceFilters, formErrors, updateFormError }}
          shouldShowSortAndLimit={false}
        />
        {createAudienceButton}
      </>
    );
  } else {
    filtersAndOrCreateAudienceButton = createAudienceButton;
  }

  if (createLookalikeLoading || (newAudienceId && newAudienceIsPending) || newAudienceIsActive) {
    return (
      <>
        Creating lookalike...
        <Loading center={false} />
      </>
    );
  } else if (newAudienceIsError) {
    return <AudienceErrorInfoBox audience={newAudience} />;
  }

  let mainDisplay;
  if (selectedAudience) {
    mainDisplay = (
      <>
        <Box sx={{ margin: "20px 0" }}>{audienceNameField}</Box>
        <Typography variant="h4">Requested number of lookalikes</Typography>
        <Typography>
          We'll expand your audience by finding similar people. As you increase the requested number
          of lookalikes, the similarity of the expanded audience to your original list will
          decrease.
        </Typography>

        <Grid container alignItems="center" spacing={3} sx={{ padding: "1rem 0 1rem 0.5rem" }}>
          <Grid item xs>
            <Slider
              getAriaLabel={() => "Size"}
              getAriaValueText={userFriendlyNumber}
              max={NUM_LOOKALIKES_MAX}
              min={NUM_LOOKALIKES_MIN}
              onChange={(e, newSize) => setLookalikeSize(newSize)}
              step={NUM_LOOKALIKES_STEP}
              value={lookalikeSize}
              valueLabelDisplay="auto"
              valueLabelFormat={userFriendlyNumber}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", color: COLORS.darkerGray }}
            >
              <Typography
                onClick={() => setLookalikeSize(NUM_LOOKALIKES_MIN)}
                sx={{ cursor: "pointer" }}
                variant="body2"
              >
                {userFriendlyNumber(NUM_LOOKALIKES_MIN)}
              </Typography>
              <Typography
                onClick={() => setLookalikeSize(NUM_LOOKALIKES_MAX)}
                sx={{ cursor: "pointer" }}
                variant="body2"
              >
                {userFriendlyNumber(NUM_LOOKALIKES_MAX)}
              </Typography>
            </Box>
          </Grid>
          <Grid item width={160}>
            <TextField
              InputProps={{
                step: NUM_LOOKALIKES_STEP,
                min: NUM_LOOKALIKES_MIN,
                max: NUM_LOOKALIKES_MAX,
                type: "number",
              }}
              onBlur={handleLookalikeSizeInputBlur}
              onChange={e => setLookalikeSize(e.target.value === "" ? 0 : Number(e.target.value))}
              size="small"
              value={lookalikeSize || ""}
            />
          </Grid>
        </Grid>
        <FormControlLabel
          control={
            <Switch
              checked={includeOriginalAudience}
              inputProps={{ "aria-label": "controlled" }}
              onChange={e => setIncludeOriginalAudience(e.target.checked)}
            />
          }
          label="Include original audience"
          sx={{ marginBottom: "1rem" }}
        />
        <br />
        <FormControlLabel
          control={
            <Switch
              checked={shouldShowFilters}
              inputProps={{ "aria-label": "controlled" }}
              onChange={e => setShouldShowFilters(e.target.checked)}
            />
          }
          label="Apply filters"
          sx={{ marginBottom: "1rem" }}
        />
        {filtersAndOrCreateAudienceButton}
      </>
    );
  }

  const topInfoBox =
    audiencesReadyforLookalikeCreation.length === 0 ? (
      <InfoBox backgroundColor="veryLightMagenta" marginBottom="1rem">
        You don't currently have any audiences that are eligible for expansion. To expand an
        audience, first{" "}
        <Link to="/audiences/match">
          <strong>match a CSV to the voter file</strong>
        </Link>
        .
      </InfoBox>
    ) : null;

  let lowerInfoBoxContent;
  if (!selectedAudience || selectedAudience.canCreateLookalikeFrom) {
    // No info box needed.
    lowerInfoBoxContent = null;
  } else if (isFilteredFromCsv) {
    let secondSectionContent;
    if (
      filtersSummaries &&
      Object.keys(filtersSummaries).length === 1 &&
      filtersSummaries["sortAndLimit"]
    ) {
      secondSectionContent = <>.</>;
    } else {
      secondSectionContent = (
        <>
          , starting with the <strong>same filters used in your selected audience</strong>. You can
          modify those filters below.
        </>
      );
    }
    const audienceFilteredFrom = csvAudienceFilteredFrom || currentVersionOfCsvAudienceFilteredFrom;
    let audienceFilteredFromNotCurrentVersionCopy = "";
    if (currentVersionOfCsvAudienceFilteredFrom) {
      audienceFilteredFromNotCurrentVersionCopy = " current version of the";
    }
    const firstSectionContent = (
      <>
        You are expanding your audience by finding people who are similar to the{" "}
        <strong>
          {userFriendlyNumber(audienceFilteredFrom.metadata.size)} people in the
          {audienceFilteredFromNotCurrentVersionCopy} audience{" "}
          <Link to={`/audiences/${audienceFilteredFrom.id}`}>{audienceFilteredFrom.name}</Link>
        </strong>{" "}
        (the audience that your selected audience was based on)
      </>
    );
    lowerInfoBoxContent = (
      <>
        {firstSectionContent}
        {secondSectionContent}
      </>
    );
  } else {
    lowerInfoBoxContent = <>You can't create a lookalike from this audience.</>;
    mainDisplay = null;
  }

  return (
    <Box maxWidth="sm">
      {topInfoBox}
      <SelectAudience
        {...{ additionalAudiencesFilter, selectedAudience, setSelectedAudience }}
        isDisabled={audiencesReadyforLookalikeCreation.length === 0}
        subtitle={VALID_AUDIENCES_FOR_LOOKALIKES_COPY}
      />
      {lowerInfoBoxContent ? (
        <InfoBox backgroundColor="veryLightMagenta">{lowerInfoBoxContent}</InfoBox>
      ) : null}
      {mainDisplay}
    </Box>
  );
};

export default CreateLookalike;
