import { Grid } from "@mui/material";

import { COLORS } from "../../colors";

const TwoColumnLayoutWithPreview = ({ mainContent, previewContent }) => {
  return (
    <Grid container spacing={0} sx={{ marginBottom: "25px" }}>
      <Grid
        item
        lg={7}
        md={8}
        sx={{ borderRight: `1px solid ${COLORS.black}`, paddingRight: "1.5rem" }}
        xl={6}
      >
        {mainContent}
      </Grid>
      <Grid item lg={5} md={4} sx={{ paddingLeft: "1.5rem" }} xl={6}>
        {previewContent}
      </Grid>
    </Grid>
  );
};

export default TwoColumnLayoutWithPreview;
