import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";

const ModalCloseButton = ({ callback }) => (
  <IconButton aria-label="close" onClick={callback}>
    <CloseIcon />
  </IconButton>
);

const getModalBoxStyle = ({ width }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: width || 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  maxHeight: "calc(100vh - 50px)",
  overflowY: "auto",
});

// @todo consider only rendering this from where it's used when it's isOpen=true, i.e. use whether
// it's rendered or not to determine whether to show it or not. This can make it easier to manage
// whether it's displayed or not, and you don't have to worry about "lingering state".
const SharedModal = ({
  setIsOpen,
  isOpen,
  showCloseButton = true,
  onClose,
  width = 400,
  children,
  title,
}) => {
  const closeModal = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <Modal onClose={closeModal} open={isOpen}>
      <Box sx={getModalBoxStyle({ width })}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent={title ? "space-between" : "flex-end"}
          spacing={2}
          sx={{ padding: `${title ? "1.25" : "0.5"}rem 1rem 0.5rem 2rem` }}
        >
          {title ? <Typography variant="h2">{title}</Typography> : null}
          {showCloseButton ? (
            <Box>
              <ModalCloseButton callback={closeModal} />
            </Box>
          ) : null}
        </Stack>
        <Box sx={{ padding: "0.5rem 2rem 2rem" }}>{children}</Box>
      </Box>
    </Modal>
  );
};

export default SharedModal;
