import { gql, useMutation } from "@apollo/client";
import { Box, Button, FormHelperText, Grid, Slider, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import { FormSection } from "../../common/layout";
import { CONTENT_GROUP_TYPES } from "../../constants";
import { CORE_OUTREACH_CAMPAIGN_FIELDS } from "../../fragments";
import {
  doesApolloErrorIncludeErrorOfType,
  formatDateForBackend,
  formatDateTimeForBackend,
  userFriendlyWholeDollars,
} from "../../helpers";
import useFormErrors from "../../hooks/useFormErrors";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import useGetAudienceFromParam from "../../hooks/useGetAudienceFromParam";
import useGetContentGroupFromParam from "../../hooks/useGetContentGroupFromParam";
import useRefreshOrgCredits from "../../hooks/useRefreshOrgCredits";
import SelectAudience from "../audience/SelectAudience";
import SelectContentGroup from "../content/SelectContentGroup";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import InfoBox from "../shared/InfoBox";
import MatchbookDatePicker from "../shared/MatchbookDatePicker";
import PurchaseCreditsDirectly from "../shared/PurchaseCreditsDirectly";
import TextLabel from "../shared/TextLabel";
import TwoColumnLayoutWithPreview from "../shared/TwoColumnLayoutWithPreview";

import CampaignFundraisingEstimate from "./CampaignFundraisingEstimate";
import OutreachCampaignsHeader from "./OutreachCampaignsHeader";
import WarningIfNoActiveAudiencesOrContent from "./WarningIfNoActiveAudiencesOrContent";

const DEFAULT_BUDGET = 3000;

const CREATE_OUTREACH_CAMPAIGN = gql`
  ${CORE_OUTREACH_CAMPAIGN_FIELDS}
  mutation CreateOutreachCampaign($input: OutreachCampaignInput!) {
    createOutreachCampaign(input: $input) {
      ...CoreOutreachCampaignFields
      organization {
        id
        numCredits
      }
    }
  }
`;

// @todo-payments consolidate with AudienceDetail export UI which is similar.
const CampaignPricingInfo = () => {
  const { organization } = useGetActiveOrg();
  const { constants } = useAppState();

  if (organization.isExemptFromPayments) {
    return null;
  }

  let insufficientCreditsMessage;
  if (organization.numCredits < constants.CAMPAIGN_CREATION_PRICE) {
    insufficientCreditsMessage = (
      <PurchaseCreditsDirectly
        numCredits={constants.CAMPAIGN_CREATION_PRICE}
        returnPath="/campaigns/new"
      />
    );
  }

  return (
    <InfoBox
      backgroundColor={insufficientCreditsMessage ? "lightRed" : "lightOrange"}
      marginBottom="1.5rem"
      typography="body1"
    >
      To create a campaign, you must use {constants.CAMPAIGN_CREATION_PRICE} credits.
      {insufficientCreditsMessage}
    </InfoBox>
  );
};

const CreateOutreachCampaign = () => {
  const dispatch = useAppStateDispatch();

  const navigate = useNavigate();
  const { formErrors, validateFieldIsPresent, updateFormError } = useFormErrors();
  const { constants } = useAppState();

  const { organization } = useGetActiveOrg();
  const selectedAudience = useGetAudienceFromParam();
  const selectedContentGroup = useGetContentGroupFromParam();

  const [outreachCampaignName, setOutreachCampaignName] = useState("");
  const [budget, setBudget] = useState(DEFAULT_BUDGET);
  const [deliveryDateStart, setDeliveryDateStart] = useState(null);
  const [deliveryDateEnd, setDeliveryDateEnd] = useState(null);
  const [deliveryDateTime, setDeliveryDateTime] = useState(null);
  const [actblueCode, setActblueCode] = useState("");
  const [platform, setPlatform] = useState(null);

  const selectedContentType = selectedContentGroup && selectedContentGroup.type;
  const isSelectedContentTextMessage = selectedContentType === "TextMessage";
  const isSelectedContentDigitalAd = selectedContentType === "DigitalAd";
  const platformOptionsForContentType = useMemo(
    () => selectedContentType && constants.CAMPAIGN_PLATFORMS[selectedContentType],
    [selectedContentType, constants],
  );

  const refreshOrgCredits = useRefreshOrgCredits();

  const insufficientCredits =
    organization.numCredits < constants.CAMPAIGN_CREATION_PRICE &&
    !organization.isExemptFromPayments;

  // Select a default platform based on the options for the currently selected content type.
  useEffect(() => {
    if (platformOptionsForContentType) {
      setPlatform(platformOptionsForContentType[0].value);
    } else {
      setPlatform(null);
    }
  }, [platformOptionsForContentType]);

  const [doCreateOutreachCampaign, { loading }] = useMutation(CREATE_OUTREACH_CAMPAIGN);

  const createOutreachCampaign = () => {
    let validationFailed;
    if (!validateFieldIsPresent("outreachCampaignName", outreachCampaignName)) {
      validationFailed = true;
    }
    if (isSelectedContentTextMessage) {
      if (!validateFieldIsPresent("deliveryDateTime", deliveryDateTime)) {
        validationFailed = true;
      }
    } else {
      if (!validateFieldIsPresent("deliveryDateStart", deliveryDateStart)) {
        validationFailed = true;
      }
      if (!validateFieldIsPresent("deliveryDateEnd", deliveryDateEnd)) {
        validationFailed = true;
      }
    }
    if (validationFailed) {
      return;
    }
    if (!organization.isExemptFromPayments) {
      const confirmed = window.confirm(
        `Are you sure you want to use ${constants.CAMPAIGN_CREATION_PRICE} credits to create a ` +
          "campaign?",
      );
      if (!confirmed) {
        return;
      }
    }
    doCreateOutreachCampaign({
      variables: {
        input: {
          organizationId: organization.id,
          name: outreachCampaignName,
          audienceId: selectedAudience.id,
          contentGroupTypedId: selectedContentGroup.typedId,
          budget: isSelectedContentTextMessage ? null : budget,
          actblueCode: actblueCode || null,
          platform,
          deliveryDateStart: !isSelectedContentTextMessage
            ? formatDateForBackend(deliveryDateStart)
            : null,
          deliveryDateEnd: !isSelectedContentTextMessage
            ? formatDateForBackend(deliveryDateEnd)
            : null,
          deliveryDateTime: isSelectedContentTextMessage
            ? formatDateTimeForBackend(deliveryDateTime)
            : null,
        },
      },
      onCompleted: data => {
        dispatch({
          type: "org-add-or-update-outreach-campaign",
          organizationId: organization.id,
          outreachCampaign: data.createOutreachCampaign,
        });
        dispatch({
          type: "org-update-num-credits",
          organizationId: organization.id,
          numCredits: data.createOutreachCampaign.organization.numCredits,
        });
        // This dispatch is to 1. show the content group is locked in the UI and 2. show the
        // campaign as associated with the content group on the content group detail page.
        dispatch({
          type: "org-update-content-group",
          contentGroup: data.createOutreachCampaign.contentGroup,
          organizationId: data.createOutreachCampaign.organization.id,
        });
        navigate(`/campaigns/${data.createOutreachCampaign.id}`);
      },
      onError: e => {
        // @todo handle other possible errors?
        if (doesApolloErrorIncludeErrorOfType(e, "InsufficientCreditsAvailable")) {
          refreshOrgCredits();
        }
      },
    });
  };

  const setAudienceOrContentGroup = ({ newAudience, newContentGroup }) => {
    const audience = newAudience || selectedAudience;
    const contentGroup = newContentGroup || selectedContentGroup;
    const audiencePath = audience ? `/audience/${audience.id}` : "";
    const contentGroupPath = contentGroup ? `/content/${contentGroup.typedId}` : "";
    navigate(`/campaigns/new${audiencePath}${contentGroupPath}`);
  };

  const isSubmitDisabled =
    Object.keys(formErrors).length > 0 ||
    !selectedAudience ||
    !selectedContentGroup ||
    loading ||
    insufficientCredits;

  let budgetDisplay, deliveryDateDisplay;
  if (isSelectedContentTextMessage) {
    deliveryDateDisplay = (
      <>
        <Grid item xs={4}>
          Delivery date
        </Grid>
        <Grid container item xs={8}>
          <MatchbookDatePicker
            disablePast
            isDateTimePicker
            onChange={(newValue, context) => {
              validateFieldIsPresent("deliveryDateTime", newValue);
              if (context.validationError) {
                updateFormError("deliveryDateTime", "Invalid date or time");
              } else {
                setDeliveryDateTime(newValue);
              }
            }}
            value={deliveryDateTime}
          />
          <FormHelperText style={{ color: "red", marginLeft: "5px", marginTop: "8px" }}>
            {formErrors.deliveryDateTime}
          </FormHelperText>
        </Grid>
      </>
    );
  } else {
    budgetDisplay = (
      <>
        <Grid item xs={4}>
          Budget
        </Grid>
        <Grid item xs={8}>
          <Slider
            getAriaLabel={() => "Budget"}
            getAriaValueText={v => v}
            marks={[
              {
                value: budget,
                label: userFriendlyWholeDollars(budget),
              },
            ]}
            max={constants.CAMPAIGN_BUDGET_MAX}
            min={constants.CAMPAIGN_BUDGET_MIN}
            onChange={(e, newBudget) => setBudget(newBudget)}
            step={25}
            value={budget}
            valueLabelDisplay="off"
          />
        </Grid>
      </>
    );
    deliveryDateDisplay = (
      <>
        <Grid item xs={4}>
          Delivery dates
        </Grid>
        {/* @todo (applies above too)
          1. add red border when in error state
          2. avoid over-eagerly showing "invalid date" while manually entering date
          3. extract a "delivery date" picker component for start and end (maybe not necessary)
        */}
        <Grid container item xs={8}>
          <Grid item sx={{ paddingRight: "4px" }} xs={6}>
            <MatchbookDatePicker
              disablePast
              maxDate={deliveryDateEnd}
              onChange={(newValue, context) => {
                validateFieldIsPresent("deliveryDateStart", newValue);
                if (context.validationError) {
                  updateFormError("deliveryDateStart", "Invalid date");
                } else {
                  setDeliveryDateStart(newValue);
                }
              }}
              slotProps={{ textField: { label: "From" } }}
              value={deliveryDateStart}
            />
            <FormHelperText style={{ color: "red" }}>{formErrors.deliveryDateStart}</FormHelperText>
          </Grid>
          <Grid item sx={{ paddingLeft: "4px" }} xs={6}>
            <MatchbookDatePicker
              disablePast
              minDate={deliveryDateStart}
              onChange={(newValue, context) => {
                validateFieldIsPresent("deliveryDateEnd", newValue);
                if (context.validationError) {
                  updateFormError("deliveryDateEnd", "Invalid date");
                } else {
                  setDeliveryDateEnd(newValue);
                }
              }}
              slotProps={{ textField: { label: "To" } }}
              value={deliveryDateEnd}
            />
            <FormHelperText style={{ color: "red" }}>{formErrors.deliveryDateEnd}</FormHelperText>
          </Grid>
        </Grid>
      </>
    );
  }

  const platformLabelDisplay =
    selectedContentType && CONTENT_GROUP_TYPES[selectedContentType].platformLabelDisplay;
  let selectPlatformDisplay;
  if (platformLabelDisplay && platformOptionsForContentType) {
    selectPlatformDisplay = (
      <>
        <Grid item xs={4}>
          {platformLabelDisplay}
        </Grid>
        <Grid item xs={8}>
          <DropdownSelectOne
            onChange={value => setPlatform(value)}
            options={platformOptionsForContentType}
            // Ensure we don't have any invalid options while switching between content types
            value={platformOptionsForContentType.find(o => o.value === platform) ? platform : null}
          />
        </Grid>
      </>
    );
  }

  // @todo this text field is very similar to the one in CreateContentGroupTextWithImage
  const mainContent = (
    <Box maxWidth="sm">
      <FormSection>
        <TextLabel>Name your campaign:</TextLabel>
        <TextField
          fullWidth
          autoComplete="off"
          error={!!formErrors.outreachCampaignName}
          helperText={formErrors.outreachCampaignName ? formErrors.outreachCampaignName : ""}
          margin="dense"
          name="outreach-campaign-name"
          onChange={e => {
            setOutreachCampaignName(e.target.value);
            validateFieldIsPresent("outreachCampaignName", e.target.value);
          }}
          size="small"
        />
      </FormSection>

      <FormSection>
        <SelectAudience
          label="Select an audience"
          selectedAudience={selectedAudience}
          setSelectedAudience={newAudience => setAudienceOrContentGroup({ newAudience })}
        />
      </FormSection>

      <FormSection>
        <SelectContentGroup
          selectedContentGroup={selectedContentGroup}
          setSelectedContentGroup={newContentGroup =>
            setAudienceOrContentGroup({ newContentGroup })
          }
        />
      </FormSection>

      <FormSection>
        <Typography variant="h4">Delivery settings</Typography>
        <Grid container alignItems="center" spacing={2} sx={{ padding: "1rem 1rem 0 0" }}>
          {budgetDisplay}
          {selectPlatformDisplay}
          {deliveryDateDisplay}
          <Grid item xs={4}>
            ActBlue code
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              margin="dense"
              name="outreach-campaign-actblue-code"
              onChange={e => {
                setActblueCode(e.target.value);
              }}
              size="small"
              value={actblueCode}
            />
          </Grid>
        </Grid>
      </FormSection>

      <FormSection>
        <Button disabled={isSubmitDisabled} onClick={createOutreachCampaign}>
          Create campaign
        </Button>
      </FormSection>
    </Box>
  );

  const previewContent = isSelectedContentDigitalAd ? (
    <>
      <Typography marginBottom="2rem">
        Create this campaign to unlock performance estimates.
      </Typography>

      <CampaignFundraisingEstimate isPlaceholder />
    </>
  ) : (
    <Typography>
      Performance estimates are currently only available for digital ad campaigns.
    </Typography>
  );

  return (
    <>
      <OutreachCampaignsHeader
        headerText="Create a campaign"
        showNewOutreachCampaignButton={false}
      />
      <WarningIfNoActiveAudiencesOrContent alternateContent={<CampaignPricingInfo />} />
      <Box marginTop="1rem">
        <TwoColumnLayoutWithPreview mainContent={mainContent} previewContent={previewContent} />
      </Box>
    </>
  );
};

export default CreateOutreachCampaign;
