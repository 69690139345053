import { gql, useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { last, sortBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useAppStateDispatch } from "../../AppStateContext";
import { CORE_DIGITAL_AD_FUNDRAISING_ESTIMATE_FIELDS } from "../../fragments";
import DropdownSelectOne from "../shared/DropdownSelectOne";
import Loading from "../shared/Loading";

import CampaignFundraisingEstimate from "./CampaignFundraisingEstimate";

const MINUTES_TO_WAIT_FOR_FUNDRAISING_ESTIMATES = 25;
const POLL_INTERVAL = 4000;

const GET_ESTIMATES = gql`
  ${CORE_DIGITAL_AD_FUNDRAISING_ESTIMATE_FIELDS}
  query getCampaignFundraisingEstimates($campaignId: ID!) {
    campaignFundraisingEstimates(campaignId: $campaignId) {
      ...CoreDigitalAdFundraisingEstimateFields
    }
  }
`;

const CampaignFundraisingEstimatesSection = ({
  estimates,
  budget,
  audienceSize,
  outreachCampaign,
}) => {
  const dispatch = useAppStateDispatch();
  const bestEstimate = useMemo(() => last(sortBy(estimates, ["dollarsRaised"])), [estimates]);
  const estimateOptions = useMemo(
    () => estimates.map(e => ({ value: e.id, label: e.contentVariation.name })),
    [estimates],
  );
  const [selectedEstimate, setSelectedEstimate] = useState(bestEstimate);

  // Handle when new estimates come in after the component has rendered.
  useEffect(() => {
    if (bestEstimate && !selectedEstimate) {
      setSelectedEstimate(bestEstimate);
    }
  }, [bestEstimate, selectedEstimate]);

  const estimatesArePresent = estimates.length > 0;
  const now = dayjs();
  const campaignCreatedMinutesAgo = now.diff(dayjs(outreachCampaign.createdAt), "minute");
  // Don't poll indefinitely, stop polling after MINUTES_TO_WAIT_FOR_FUNDRAISING_ESTIMATES in case
  // something went wrong in the async job that generates the estimates.
  const isWithinPollingWindow =
    campaignCreatedMinutesAgo <= MINUTES_TO_WAIT_FOR_FUNDRAISING_ESTIMATES;

  const {
    data: estimatesResult,
    loading,
    stopPolling,
  } = useQuery(GET_ESTIMATES, {
    skip: estimatesArePresent || !isWithinPollingWindow,
    pollInterval: POLL_INTERVAL,
    variables: { campaignId: outreachCampaign.id },
  });

  const outreachCampaignId = outreachCampaign.id;
  const organizationId = outreachCampaign.organization.id;

  useEffect(() => {
    if (estimatesResult && estimatesResult.campaignFundraisingEstimates.length > 0) {
      // Stop polling once we get estimates. Note that the `skip` logic above doesn't
      // handle this case since it only stops the polling from starting, it doesn't
      // stop it once it starts.
      stopPolling();
      dispatch({
        type: "org-update-outreach-campaign-fundraising-estimates",
        organizationId,
        outreachCampaignId,
        campaignContentVariationDigitalAdFundraisingEstimates:
          estimatesResult.campaignFundraisingEstimates,
      });
    }
  }, [estimatesResult, dispatch, organizationId, outreachCampaignId, stopPolling]);

  useEffect(() => {
    if (!isWithinPollingWindow) {
      stopPolling();
    }
  }, [loading, isWithinPollingWindow, stopPolling]);

  const isPollingInferred = isWithinPollingWindow && !estimatesArePresent;

  let mainContent;
  if (estimatesArePresent && selectedEstimate) {
    mainContent = (
      <>
        <Box sx={{ maxWidth: "400px" }}>
          <DropdownSelectOne
            onChange={estimateId => setSelectedEstimate(estimates.find(e => e.id === estimateId))}
            options={estimateOptions}
            value={selectedEstimate.id}
          />
        </Box>
        <Box marginTop="1rem">
          <Link to={`/content/variation/${selectedEstimate.contentVariation.typedId}`}>
            View variation
          </Link>
        </Box>
        <CampaignFundraisingEstimate
          estimate={selectedEstimate}
          {...{ budget, audienceSize, outreachCampaign }}
        />
      </>
    );
  } else if (isPollingInferred) {
    mainContent = (
      <Box>
        <Loading center={false} /> Estimates are being generated. This may take a minute or two.
      </Box>
    );
  } else {
    // No estimates are present and we're outside of the polling window. This shouldn't happen.
    mainContent = (
      <Typography>
        There was an issue generating performance estimates. Please{" "}
        <Link to="/help">reach out for help</Link>.
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h2">Performance estimates:</Typography>
      {mainContent}
    </>
  );
};

export default CampaignFundraisingEstimatesSection;
