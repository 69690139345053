import "./App.css";
import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import "react-toastify/dist/ReactToastify.css";
import { Box, CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AppStateProvider, useAppStateDispatch, useAppState } from "./AppStateContext";
import Nav from "./components/Nav";
import ThemeWrapper from "./components/ThemeWrapper";
import Loading from "./components/shared/Loading";
import { TOP_NAV_HEIGHT } from "./constants";
import { LOGGED_IN_USER_FIELDS } from "./fragments";
import useScrollToTopOnPathChange from "./hooks/useScrollToTopOnPathChange";

const AppWrapper = styled.div`
  margin: 0 30px;
`;

export const GET_USER = gql`
  ${LOGGED_IN_USER_FIELDS}
  query getUser {
    user {
      ...RootUserFields
    }
  }
`;

export const GET_CONSTANTS = gql`
  query getConstants {
    getConstants {
      constants
    }
  }
`;

const Fallback = () => {
  return (
    <h3 style={{ margin: "2rem", textAlign: "center" }}>
      Whoops, something went wrong. Please try again later.
    </h3>
  );
};

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      <AppStateProvider>
        <ThemeWrapper>
          {/* This is needed to provide localization info for date/time related functions. */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ToastContainer draggable={false} hideProgressBar={true} position="top-center" />
            <AppWrapper>
              <InnerApp />
            </AppWrapper>
          </LocalizationProvider>
        </ThemeWrapper>
      </AppStateProvider>
    </Sentry.ErrorBoundary>
  );
};

const InnerApp = () => {
  const dispatch = useAppStateDispatch();
  const { user, constants } = useAppState();
  useScrollToTopOnPathChange();

  useQuery(GET_USER, {
    onCompleted: data => dispatch({ type: "user-and-orgs-set", user: data.user }),
  });

  useQuery(GET_CONSTANTS, {
    onCompleted: data =>
      dispatch({ type: "constants-set", constants: data.getConstants.constants }),
  });

  // User is still loading or being updated in state
  if (user === undefined || !constants) {
    return <Loading />;
  } else if (!user) {
    return <Outlet />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Nav />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          maxWidth: "lg",
          height: "100%",
          marginTop: `${TOP_NAV_HEIGHT}px`,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default App;
