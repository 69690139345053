import { Box, Button, Grid, TextField, Chip, Typography } from "@mui/material";
import { sortBy, reverse } from "lodash";
import { useEffect, useState } from "react";

import { audienceType, formatDate, userFriendlyNumber } from "../../helpers";
import useGetCurrentAudiences from "../../hooks/useGetCurrentAudiences";
import { OneLineTypography } from "../../styles";
import CardItem from "../shared/CardItem";
import Loading from "../shared/Loading";
import XOutAdornment from "../shared/XOutAdornment";

import AudiencesHeader from "./AudiencesHeader";

const AudienceListItem = ({ audience }) => {
  const { id, name, metadata, createdAt, isArchived, status } = audience;
  const isPending = status === "PENDING";
  const requiresPayment = status === "REQUIRES_PAYMENT";

  let chip;
  if (isArchived) {
    chip = <Chip label="Archived" size="small" />;
  } else if (requiresPayment) {
    chip = <Chip color="warning" label="Locked" size="small" />;
  }

  const activeContent = (
    <>
      <OneLineTypography>{metadata && userFriendlyNumber(metadata.size)} people</OneLineTypography>
      <OneLineTypography>
        {metadata && userFriendlyNumber(metadata.num_phones)} phone numbers
      </OneLineTypography>
      <Grid container columnSpacing={1}>
        <Grid item xs sx={{ overflow: "hidden" }}>
          <OneLineTypography>
            {metadata && userFriendlyNumber(metadata.num_emails)} emails
          </OneLineTypography>
        </Grid>
        {chip ? (
          <Grid item sx={{ marginLeft: "auto" }}>
            {chip}
          </Grid>
        ) : null}
      </Grid>
    </>
  );

  const pendingContent = (
    <Box>
      <Typography sx={{ fontStyle: "italic" }}>Creating audience...</Typography>
      <Loading center={false} />
    </Box>
  );

  return (
    <CardItem {...{ isArchived, isPending, requiresPayment }} linkTo={`/audiences/${id}`}>
      <Box sx={{ marginBottom: "0.5rem" }}>
        <OneLineTypography sx={{ marginBottom: "0.25rem" }} title={name} variant="h3">
          {name}
        </OneLineTypography>
        <OneLineTypography title={audienceType(audience)} variant="subtitle2">
          {audienceType(audience)}
        </OneLineTypography>
        <OneLineTypography variant="subtitle2">{formatDate(createdAt)}</OneLineTypography>
      </Box>
      {isPending ? pendingContent : activeContent}
    </CardItem>
  );
};

const AudienceList = () => {
  const [shouldIncludeArchived, setShouldIncludeArchived] = useState(
    JSON.parse(localStorage.getItem("shouldIncludeArchived")),
  );
  const [audienceSearch, setAudienceSearch] = useState("");

  const getAudiencesParams = {
    includeArchived: !!shouldIncludeArchived,
    includePending: true,
    includeRequiresPayment: true,
  };
  const audiences = useGetCurrentAudiences(getAudiencesParams);
  const hasAnyArchived = useGetCurrentAudiences({
    ...getAudiencesParams,
    includeArchived: true,
  }).some(a => a.isArchived);

  useEffect(() => {
    localStorage.setItem("shouldIncludeArchived", JSON.stringify(shouldIncludeArchived));
  }, [shouldIncludeArchived]);

  let filteredAudiences = audiences;
  if (audienceSearch) {
    filteredAudiences = filteredAudiences.filter(audience =>
      audience.name.toLowerCase().includes(audienceSearch.toLowerCase()),
    );
  }
  const audienceList = reverse(sortBy(filteredAudiences, "createdAt")).map(audience => (
    <AudienceListItem audience={audience} key={audience.id} />
  ));

  const headerRightContent = (
    <>
      <Grid item>
        {hasAnyArchived ? (
          <Button onClick={() => setShouldIncludeArchived(!shouldIncludeArchived)}>
            {shouldIncludeArchived ? "Hide" : "Show"} archived
          </Button>
        ) : null}
      </Grid>
      {/* @todo-ui-later add table view */}
      {/* <Grid item>
        <Button onClick={() => {}}>Table view</Button>
      </Grid> */}
    </>
  );

  let audienceListUi;
  if (filteredAudiences.length > 0) {
    // There are audiences to show
    audienceListUi = (
      <Grid container spacing={4}>
        {audienceList}
      </Grid>
    );
  } else if (filteredAudiences.length === 0 && (audiences.length > 0 || hasAnyArchived)) {
    // There are audiences, but they're not visible either because we're not showing archived ones
    // or the inputted search doesn't match any
    audienceListUi = <Box>No audiences found.</Box>;
  } else if (!hasAnyArchived) {
    audienceListUi = (
      <Box>
        To create your first audience, click the "New" button above and select one of these options:
        <ul>
          <li>
            <strong>Apply filters to national voter file</strong>
          </li>
          <li>
            <strong>Match a CSV to the voter file</strong>
          </li>
        </ul>
      </Box>
    );
  }

  return (
    <>
      <AudiencesHeader alternateRightContent={headerRightContent} headerText="Audiences" />
      {audiences.length > 0 ? (
        <TextField
          InputProps={{
            endAdornment: (
              <XOutAdornment disabled={!audienceSearch} onClick={() => setAudienceSearch("")} />
            ),
          }}
          label="Search audiences"
          margin="dense"
          onChange={e => setAudienceSearch(e.target.value)}
          size="small"
          sx={{ marginBottom: "1rem" }}
          value={audienceSearch}
        />
      ) : null}
      {audienceListUi}
    </>
  );
};

export default AudienceList;
