import { gql, useMutation } from "@apollo/client";
import CampaignIcon from "@mui/icons-material/Campaign";
import LogoutIcon from "@mui/icons-material/Logout";
import MmsIcon from "@mui/icons-material/Mms";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SearchIcon from "@mui/icons-material/Search";
// import PersonIcon from "@mui/icons-material/Person";
// import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
// import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  AppBar,
  Button,
  styled,
} from "@mui/material";
import pluralize from "pluralize";
import { NavLink, Link } from "react-router-dom";

import { useAppState } from "../AppStateContext";
import { COLORS } from "../colors";
import { TOP_NAV_HEIGHT } from "../constants";
import { userFriendlyNumber } from "../helpers";
import useGetActiveOrg from "../hooks/useGetActiveOrg";

import OrgPicker from "./OrgPicker";
import MatchbookLogoWithText from "./svgs/MatchbookLogoWithText";

const LOGOUT = gql`
  mutation {
    logout
  }
`;

const DRAWER_WIDTH = 190;
const NAV_SECTION_1 = {
  // Home: { url: "/", icon: HouseOutlinedIcon },
  Audiences: { url: "/audiences", icon: PermContactCalendarIcon },
  Content: { url: "/content", icon: MmsIcon },
  Campaigns: { url: "/campaigns", icon: CampaignIcon },
  Experiments: { url: "/experiments", icon: SearchIcon },
};
const NAV_SECTION_2 = {
  // Account: { url: "/account", icon: PersonIcon },
  Help: { url: "/help", icon: QuestionAnswerIcon },
};

const StyledNavLink = styled(NavLink)`
  color: ${COLORS.black};
  &.active {
    & span {
      font-weight: bold;
    }
  }
`;

const LeftNav = () => {
  return (
    <Drawer
      anchor="left"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
    >
      <Toolbar
        disableGutters
        sx={{
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem 1.5rem 0 1.5rem",
        }}
      >
        <Box>
          <Link to="/">
            <MatchbookLogoWithText width="100%" />
          </Link>
        </Box>
        <Typography color={COLORS.blue} variant="body2">
          ({process.env.REACT_APP_ENV === "staging" ? "STAGING" : "beta"})
        </Typography>
      </Toolbar>
      <List>
        {Object.keys(NAV_SECTION_1).map(navKey => (
          <NavItem key={navKey} navValue={NAV_SECTION_1[navKey]} text={navKey} />
        ))}
      </List>
      <List>
        {Object.keys(NAV_SECTION_2).map(navKey => (
          <NavItem key={navKey} navValue={NAV_SECTION_2[navKey]} text={navKey} />
        ))}
      </List>
    </Drawer>
  );
};

const TopNav = () => {
  const { organization } = useGetActiveOrg();
  const { user } = useAppState();
  const [doLogoutMutation] = useMutation(LOGOUT);

  const logout = () => {
    doLogoutMutation({
      onCompleted: () => {
        window.location.reload();
      },
    });
  };

  const creditDisplay = organization.isExemptFromPayments ? null : (
    <>
      :{" "}
      <Link to="/credits/purchase">
        {userFriendlyNumber(organization.numCredits)} {pluralize("credit", organization.numCredits)}
      </Link>
    </>
  );

  return (
    <AppBar
      position="absolute"
      sx={{
        backgroundColor: COLORS.white,
        color: COLORS.black,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: `${DRAWER_WIDTH}px`,
        height: `${TOP_NAV_HEIGHT}px`,
      }}
    >
      <Toolbar>
        <Typography noWrap sx={{ flexGrow: 1 }}>
          <strong>{organization.name}</strong>
          {creditDisplay}
        </Typography>
        {user.isStaff ? <OrgPicker /> : null}
        <Button onClick={logout} startIcon={<LogoutIcon />} variant="text">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const NavItem = ({ text, navValue }) => {
  const IconComponent = navValue.icon;
  return (
    <ListItem disablePadding component={StyledNavLink} to={navValue.url}>
      <ListItemButton>
        <ListItemIcon sx={{ minWidth: "40px" }}>
          <IconComponent />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

const Nav = () => {
  return (
    <>
      <TopNav />
      <LeftNav />
    </>
  );
};

export default Nav;
