import { pick } from "lodash";
import pluralize from "pluralize";

import { CONTENT_GROUP_TYPES } from "../../constants";
import { userFriendlyNumber } from "../../helpers";
import useGetCurrentContentGroups from "../../hooks/useGetCurrentContentGroups";
import SelectOrgRelatedObject from "../shared/SelectOrgRelatedObject";

const SelectContentGroup = ({
  selectedContentGroup,
  setSelectedContentGroup,
  isDisabled = false,
  showLabel = true,
  placeholder = "",
  subtitle = null,
}) => {
  const contentGroups = useGetCurrentContentGroups();

  const generateLabelForOption = contentGroup => {
    const numVariations = contentGroup.variations.length;
    const typeString = CONTENT_GROUP_TYPES[contentGroup.type].name.toLowerCase();
    return `${contentGroup.name} (${userFriendlyNumber(numVariations)} ${typeString} ${pluralize(
      "variation",
      numVariations,
    )})`;
  };
  const contentGroupToOption = contentGroup => ({
    ...pick(contentGroup, ["id", "typedId"]),
    label: generateLabelForOption(contentGroup),
  });

  const options = contentGroups.filter(cg => cg.variations.length > 0).map(contentGroupToOption);

  return (
    <SelectOrgRelatedObject
      getOptionLabel={option => option.label}
      label={showLabel ? "Select content" : null}
      selectedObject={(selectedContentGroup && contentGroupToOption(selectedContentGroup)) || null}
      setSelectedObject={setSelectedContentGroup}
      {...{ isDisabled, options, placeholder, subtitle }}
    />
  );
};

export default SelectContentGroup;
