import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import pluralize from "pluralize";

import { COLORS } from "../../colors";
import { formatPercentage, userFriendlyWholeDollars, userFriendlyRoundNumber } from "../../helpers";
import TextLabel from "../shared/TextLabel";

const PROFIT_THRESHOLD = 3;
const REACH_RATE_MIN = 0.01;
const REACH_RATE_MAX = 0.65;

// @todo circle back on this -- used with the chart below
// const EstimateBar = ({ title, value, typicalMultiplier = 0.75, formatFn = val => val }) => {
//   const predictedWidth = 80;
//   const typicalWidth = predictedWidth * typicalMultiplier;

//   return (
//     <Box>
//       <Typography variant="h3">{title}</Typography>
//       <Grid container alignItems="center" rowSpacing={0}>
//         <Grid item sx={{ fontSize: "small" }} xs={3}>
//           Predicted: {formatFn(value)}
//         </Grid>
//         <Grid item xs={9}>
//           <Box sx={{ backgroundColor: COLORS.cyan, height: "20px", width: `${predictedWidth}%` }} />
//         </Grid>
//         <Grid item sx={{ fontSize: "small" }} xs={3}>
//           Typical: {formatFn(value * typicalMultiplier)}
//         </Grid>
//         <Grid item xs={9}>
//           <Box sx={{ backgroundColor: COLORS.orange, height: "20px", width: `${typicalWidth}%` }} />
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

const CampaignFundraisingEstimate = ({
  isPlaceholder = false,
  budget,
  audienceSize,
  estimate,
  outreachCampaign,
}) => {
  const placeholderString = "████";
  let profitValue, roiValue, grossReturnValue, reachValue, impressionsValue;
  if (isPlaceholder) {
    profitValue = `$ ${placeholderString}`;
    roiValue = `${placeholderString} %`;
    reachValue = `${placeholderString} unique people`;
    grossReturnValue = (
      <>
        $ {placeholderString} from <br /> {placeholderString} unique donors
      </>
    );
    impressionsValue = `${placeholderString} impressions`;
  } else {
    const { dollarsRaised, numDonations } = estimate;

    const grossReturnBase = dollarsRaised;
    const grossReturnError = grossReturnBase * 0.45;
    const grossReturnStart = grossReturnBase - grossReturnError;
    const grossReturnEnd = grossReturnBase + grossReturnError;

    const profitStart = grossReturnStart - budget;
    const profitEnd = grossReturnEnd - budget;
    const roiStart = (grossReturnStart - budget) / budget;
    const roiEnd = (grossReturnEnd - budget) / budget;

    const donorsBase = numDonations;
    const donorsError = Math.max(donorsBase * 0.35, 1);
    const donorsStart = Math.max(donorsBase - donorsError, 0);
    const donorsEnd = donorsBase + donorsError;

    // The next few lines (until the next empty line) assume it's a digital ad campaign.
    // We'll need to diverge once we have estimates for other types of campaigns.
    const durationInHours = dayjs(outreachCampaign.deliveryDateEnd).diff(
      dayjs(outreachCampaign.deliveryDateStart),
      "hour",
    );

    const reachEnd = Math.min(
      698.6249867 + 0.02342822 * audienceSize + 7.72724496 * budget,
      REACH_RATE_MAX * audienceSize,
    );

    // reachStart should be at least 100 less than `reachEnd`, but not <0
    const minReachStart = Math.max(reachEnd - 100, 0);
    const reachStart = Math.min(
      Math.max(
        428.37586676 + 0.01912485 * audienceSize + 6.97358572 * budget,
        REACH_RATE_MIN * audienceSize,
      ),
      minReachStart,
    );

    // This is just used to calculate impressions.
    const reachMiddle = Math.min(
      Math.max(
        563.50043 + 0.02128 * audienceSize + 7.35042 * budget,
        REACH_RATE_MIN * audienceSize,
      ),
      REACH_RATE_MAX * audienceSize,
    );

    const impressionsEnd = Math.min(
      -59.22456 + 0.05526549 * audienceSize + 34.04388 * budget + 19.25251 * durationInHours,
      reachEnd * 10,
    );

    // Take the greater of the formula below and reachMiddle, but try to keep it at least 100 below
    // impressionsEnd. (And don't allow negative numbers.)
    const impressionsStart = Math.max(
      Math.min(
        Math.max(
          -1871.312 + 0.03857149 * audienceSize + 31.10268 * budget + 11.7864 * durationInHours,
          reachMiddle,
        ),
        impressionsEnd - 100,
      ),
      0,
    );

    // Formatting
    // @todo formatting (800k, 20k, etc.)?
    profitValue =
      profitStart > PROFIT_THRESHOLD
        ? `${userFriendlyWholeDollars(profitStart)} - ${userFriendlyWholeDollars(profitEnd)}`
        : "-";
    roiValue =
      profitStart > PROFIT_THRESHOLD
        ? `${formatPercentage({ value: roiStart })} - ${formatPercentage({ value: roiEnd })}`
        : "-";
    reachValue = `${userFriendlyRoundNumber(reachStart)} - ${userFriendlyRoundNumber(
      reachEnd,
    )} unique ${pluralize("person", Math.round(reachEnd))}`;
    grossReturnValue = `${userFriendlyWholeDollars(grossReturnStart)} - ${userFriendlyWholeDollars(
      grossReturnEnd,
    )} from ${userFriendlyRoundNumber(donorsStart)} - ${userFriendlyRoundNumber(
      donorsEnd,
    )} ${pluralize("donor", Math.round(donorsEnd))}`;
    impressionsValue = `${userFriendlyRoundNumber(impressionsStart)} - ${userFriendlyRoundNumber(
      impressionsEnd,
    )} ${pluralize("impression", Math.round(impressionsEnd))}`;
  }

  const previewMetrics = [
    {
      title: "Gross return",
      value: grossReturnValue,
    },
    {
      title: "Profit",
      value: profitValue,
    },
    {
      title: "Reach",
      value: reachValue,
    },
    {
      title: "Impressions",
      value: impressionsValue,
    },
    {
      title: "ROI",
      value: roiValue,
    },
  ];

  // @todo need to circle back on this.
  // Also consider making this a separate component and extracting the logic above.
  // if (isChart) {
  //   return (
  //     <Stack direction="column" maxWidth="sm" spacing={2}>
  //       <EstimateBar
  //         formatFn={userFriendlyRoundNumber}
  //         title="Reach"
  //         typicalMultiplier={0.8}
  //         value={reach}
  //       />
  //       <EstimateBar
  //         formatFn={userFriendlyWholeDollars}
  //         title="Gross return"
  //         typicalMultiplier={0.6}
  //         value={grossReturn}
  //       />
  //       <EstimateBar
  //         formatFn={val => formatPercentage({ value: val, sigFig: 2 })}
  //         title="Donation rate"
  //         typicalMultiplier={0.9}
  //         value={0.5}
  //       />
  //       <Box>
  //         <Typography variant="h3">Return on investment (predicted)</Typography>
  //         <Typography color={COLORS.blue} variant="h3">
  //           {PROFIT_THRESHOLD ? `${formatPercentage({ value: roi })}` : "-"}
  //         </Typography>
  //       </Box>
  //     </Stack>
  //   );
  // }

  return (
    <Grid container marginTop="1rem" maxWidth="600px" spacing={2}>
      {previewMetrics.map(metricSpec => (
        <Grid item key={metricSpec.title} xs={isPlaceholder ? 12 : 6}>
          <TextLabel>{metricSpec.title}</TextLabel>
          <Typography color={COLORS.blue} fontWeight="bold">
            {metricSpec.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default CampaignFundraisingEstimate;
