import { gql, useMutation } from "@apollo/client";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import { Box, Button, Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import pluralize from "pluralize";
import { useState } from "react";

import { useAppState, useAppStateDispatch } from "../../AppStateContext";
import {
  arrayToSentence,
  doesApolloErrorIncludeErrorOfType,
  formatDate,
  userFriendlyNumber,
} from "../../helpers";
import useMakeAudiencePurchase from "../../hooks/useMakeAudiencePurchase";
import ExternalLink from "../shared/ExternalLink";
import InfoBox from "../shared/InfoBox";
import Loading from "../shared/Loading";
import PurchaseCreditsDirectly from "../shared/PurchaseCreditsDirectly";

import AudienceCsvExportDownloadButton from "./AudienceCsvExportDownloadButton";

const numberFormatter = params => (params.value !== null ? userFriendlyNumber(params.value) : "--");
const creditsFormatter = params =>
  `${userFriendlyNumber(params.value)} ${pluralize("credit", params.value)}`;

const CREATE_PREVIEW_CSV_EXPORT = gql`
  mutation ($audienceId: ID!, $contactInfoFieldTypes: [String!]!) {
    createPreviewCsvExport(audienceId: $audienceId, contactInfoFieldTypes: $contactInfoFieldTypes) {
      id
      purchaseCostBreakdown
      size
      totalCost
      audience {
        id
        metadata
      }
    }
  }
`;

const AudienceCsvExportModalContent = ({ audience, organization, setIsOpen }) => {
  const { constants } = useAppState();
  const dispatch = useAppStateDispatch();

  const { AUDIENCE_FIELD_PRICING } = constants;
  const CONTACT_INFO_FIELD_TYPES = Object.fromEntries(
    Object.entries(AUDIENCE_FIELD_PRICING).filter(([ft, spec]) => spec.is_contact_info),
  );

  const [selectedFieldTypes, setSelectedFieldTypes] = useState([]);
  const [screenToDisplay, setScreenToDisplay] = useState("start");
  const [previewExport, setPreviewExport] = useState();
  const [oldAudienceSize, setOldAudienceSize] = useState();

  const [doCreatePreviewExport, { loading: isCreatePreviewExportLoading }] =
    useMutation(CREATE_PREVIEW_CSV_EXPORT);

  const resetForm = () => {
    setScreenToDisplay("start");
    setSelectedFieldTypes([]);
    setPreviewExport(null);
  };

  const { handlePurchaseExport, isPurchaseLoading } = useMakeAudiencePurchase({
    audienceId: audience.id,
    confirmPhrase: "create this export",
    successMessage: "Export generated!",
    additionalOnCompleted: resetForm,
    additionalOnError: e => {
      if (!doesApolloErrorIncludeErrorOfType(e, "InsufficientCreditsAvailable")) {
        resetForm();
      }
    },
  });

  const toggleSelectedFieldType = (fieldType, shouldInclude) => {
    const newFieldTypes = Object.keys(CONTACT_INFO_FIELD_TYPES).filter(
      ft =>
        (ft !== fieldType && selectedFieldTypes.includes(ft)) ||
        (ft === fieldType && shouldInclude),
    );
    setSelectedFieldTypes(newFieldTypes);
  };

  const paymentNeeded = !organization.isExemptFromPayments;
  const activeExports = audience.csvExports
    ? audience.csvExports.filter(e => e.status === "ACTIVE")
    : [];

  let piiFieldTypesPurchased = [];
  let sortedExports, newestExport;
  if (activeExports.length) {
    sortedExports = sortBy(activeExports, "createdAt");
    newestExport = sortedExports[sortedExports.length - 1];

    piiFieldTypesPurchased = Object.keys(newestExport.purchaseCostBreakdown).filter(
      ft => AUDIENCE_FIELD_PRICING[ft].is_contact_info,
    );
  }

  const renderStartScreen = () => {
    const handleReviewPricing = () => {
      doCreatePreviewExport({
        variables: {
          audienceId: audience.id,
          contactInfoFieldTypes: [...selectedFieldTypes, ...piiFieldTypesPurchased],
        },
        onCompleted: data => {
          const previewExport = data.createPreviewCsvExport;
          setPreviewExport(previewExport);
          if (previewExport.size !== audience.metadata.size) {
            // The export is smaller than expected because the audience has shrunk (due to voter
            // file changes). Store the previous size of the audience so that we can render an
            // explanatory message. Also, update the audience metadata on the client side to reflect
            // the new data.
            setOldAudienceSize(audience.metadata.size);
            dispatch({
              type: "org-add-or-update-audience",
              organizationId: organization.id,
              audience: previewExport.audience,
            });
          }
        },
      });
      setScreenToDisplay("review");
    };

    let existingExportsSection;
    if (activeExports.length) {
      let exportHistory;
      // @todo for simplicity, maybe just show the table even if there's only one export?
      if (sortedExports.length > 1) {
        const historyTableRows = [];
        const fieldTypesSeen = new Set();
        sortedExports.forEach(ex => {
          const fieldTypes = Object.keys(ex.purchaseCostBreakdown);
          const fieldTypesAdded = fieldTypes.filter(ft => !fieldTypesSeen.has(ft));
          fieldTypesAdded.forEach(ft => fieldTypesSeen.add(ft));

          const renderedFieldTypes = fieldTypesAdded
            .map(ft => AUDIENCE_FIELD_PRICING[ft].label)
            .toSorted()
            .join(", ");

          historyTableRows.push({
            id: ex.id,
            fieldTypesAdded: renderedFieldTypes,
            numCredits: ex.totalCost,
            createdAt: ex.createdAt,
            createdBy: `${ex.createdBy.firstName} ${ex.createdBy.lastName}`,
          });
        });
        const columnSpecs = [
          {
            field: "fieldTypesAdded",
            headerName: `Data ${paymentNeeded ? "purchased" : "exported"}`,
            width: 400,
          },
          paymentNeeded
            ? {
                // not shown if org is exempt from payments
                field: "numCredits",
                headerName: "Credits used",
                valueFormatter: numberFormatter,
              }
            : null,
          {
            field: "createdAt",
            headerName: "On",
            valueFormatter: params => formatDate(params.value),
          },
          {
            field: "createdBy",
            headerName: "By",
            width: 200,
          },
        ]
          .filter(spec => spec !== null)
          .map(c => ({ ...c, sortable: false }));

        exportHistory = (
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            hideFooter
            columns={columnSpecs}
            rows={historyTableRows}
            rowSelection={false}
          />
        );
      } else {
        const infoPurchasedSection = piiFieldTypesPurchased.length ? (
          <ul>
            {piiFieldTypesPurchased.map(ft => (
              <li key={ft}>{AUDIENCE_FIELD_PRICING[ft].label}</li>
            ))}
          </ul>
        ) : (
          <>
            None <br />
          </>
        );

        // @todo Note that this logic assumes that if the org is exempt from payments, they were
        // also exempt when they generated this export (and vice versa). If we wanted to guarantee
        // correctness, we would need to fetch the credit transaction data also. But it's not a
        // big deal for the purpose of this rendered content.
        let generatedPhrase;
        if (!paymentNeeded) {
          generatedPhrase = "Generated";
        } else if (newestExport.totalCost) {
          generatedPhrase = (
            <>
              Purchased for {userFriendlyNumber(newestExport.totalCost)}{" "}
              {pluralize("credit", newestExport.totalCost)}
            </>
          );
        } else {
          generatedPhrase = "Generated at no cost";
        }

        exportHistory = (
          <>
            Contact info exported so far for this audience: {infoPurchasedSection}
            {generatedPhrase} by {newestExport.createdBy.firstName}{" "}
            {newestExport.createdBy.lastName} on {dayjs(newestExport.createdAt).format("LL")}.
          </>
        );
      }
      existingExportsSection = (
        <>
          {exportHistory}
          <br />
          <br />
          <AudienceCsvExportDownloadButton url={newestExport.downloadUrl} />
          <br />
          <br />
        </>
      );
    }

    const piiFieldTypesNotYetPurchased = Object.keys(AUDIENCE_FIELD_PRICING).filter(
      ft => AUDIENCE_FIELD_PRICING[ft].is_contact_info && !piiFieldTypesPurchased.includes(ft),
    );

    let newExportForm;
    if (piiFieldTypesNotYetPurchased.length) {
      const fieldTypeCheckboxes = Object.entries(AUDIENCE_FIELD_PRICING)
        .filter(([fieldType, spec]) => spec.is_contact_info)
        .map(([fieldType, spec]) => {
          const alreadyPurchased = piiFieldTypesPurchased.includes(fieldType);
          const isSelected = selectedFieldTypes.includes(fieldType) || alreadyPurchased;

          let parenthetical = "";
          if (paymentNeeded) {
            parenthetical = alreadyPurchased
              ? " (already purchased)"
              : ` (${spec.price} ${pluralize("credit", spec.price)} per voter)`;
          } else if (alreadyPurchased) {
            parenthetical = " (already exported)";
          }

          let tooltip;
          if (fieldType === "phone") {
            const feeNote = paymentNeeded
              ? "However, we'll only charge the phone export fee for records that include a cell phone."
              : null;
            <>
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    If you opt to add phone numbers, we'll include both cell phones and landlines in
                    your export. {feeNote}
                  </Typography>
                }
              >
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            </>;
          }
          return (
            <div
              key={fieldType}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSelected}
                    disabled={alreadyPurchased}
                    onChange={() => toggleSelectedFieldType(fieldType, !isSelected)}
                  />
                }
                label={`${spec.label}${parenthetical}`}
              />
              {tooltip}
            </div>
          );
        });

      // If there's already been an export, then the base fee has already been covered, so they need
      // to choose at least one new contact info type.
      // @todo add tooltip explaining why it's disabled
      const buttonDisabled = !!activeExports.length && !selectedFieldTypes.length;

      let selectPrompt;
      if (activeExports.length) {
        selectPrompt = (
          <>
            Want to add more data? Select the types of contact information that you'd like to add to
            your export:
          </>
        );
      } else if (!paymentNeeded) {
        selectPrompt = (
          <>
            You can generate an export of this audience and, optionally, include one or more forms
            of contact information:
          </>
        );
      } else {
        selectPrompt = (
          <>
            The base fee for each exported voter record is 0.25 credits.
            <br />
            You can also choose to include contact information in your export for an additional fee:
          </>
        );
      }

      newExportForm = (
        <>
          {selectPrompt}
          <Box sx={{ margin: "0.5rem 0" }}>{fieldTypeCheckboxes}</Box>
          <Button disabled={buttonDisabled} onClick={handleReviewPricing}>
            Review {paymentNeeded ? "Pricing" : ""}
          </Button>
        </>
      );
    }

    return (
      <>
        {existingExportsSection}
        {newExportForm}
        <Box marginTop="1rem">
          See{" "}
          <ExternalLink to="https://stacks.indigo.engineering/matchbook">
            more information
          </ExternalLink>{" "}
          about the fields included in all exports.
        </Box>
      </>
    );
  };

  const renderReviewScreen = () => {
    const size = previewExport.size;
    const total = previewExport.totalCost;
    const costRows = Object.entries(previewExport.purchaseCostBreakdown).map(
      ([fieldType, breakdown]) => {
        const {
          price_per_record,
          count_total_available,
          count_already_paid,
          count_requiring_payment,
        } = breakdown;
        return {
          id: fieldType,
          fieldType: AUDIENCE_FIELD_PRICING[fieldType].label,
          notAvailable: fieldType === "base_export" ? null : size - count_total_available,
          alreadyPurchased: count_already_paid,
          newToPurchase: count_requiring_payment,
          pricePerRecord: price_per_record,
          totalCost: price_per_record * count_requiring_payment,
        };
      },
    );

    let summaryStatement;
    const exportNounPhrase = `export of ${userFriendlyNumber(size)} ${pluralize("person", size)}`;
    if (!paymentNeeded) {
      summaryStatement = `The table below shows the details of your proposed ${exportNounPhrase}.`;
    } else if (total) {
      let verbPhrase;
      if (activeExports.length) {
        const renderedFieldTypes = arrayToSentence(
          selectedFieldTypes.map(ft => AUDIENCE_FIELD_PRICING[ft].label.toLowerCase()),
        );
        verbPhrase = <>add {renderedFieldTypes} information to your</>;
      } else {
        verbPhrase = "generate this";
      }
      summaryStatement = (
        <>
          The cost to {verbPhrase} {exportNounPhrase} is
          <b>
            {" "}
            {userFriendlyNumber(total)} {pluralize("credit", total)}
          </b>
          . Please review the table below for more details.
        </>
      );
    } else {
      summaryStatement = `There is no cost to generate this ${exportNounPhrase}.`;
    }

    const handlePurchaseClick = () =>
      handlePurchaseExport({
        exportId: previewExport.id,
        expectedCost: paymentNeeded ? previewExport.totalCost : 0,
      });

    const handleBackClick = () => {
      setPreviewExport(null);
      setScreenToDisplay("start");
    };

    const actionSection =
      organization.numCredits >= total || !paymentNeeded ? (
        <Button onClick={handlePurchaseClick} startIcon={<PaymentIcon />}>
          {total && paymentNeeded ? "Purchase" : "Generate Export"}
        </Button>
      ) : (
        <PurchaseCreditsDirectly numCredits={total} returnPath={`/audiences/${audience.id}`} />
      );

    let sizeWarning;
    if (oldAudienceSize) {
      sizeWarning = (
        <>
          <InfoBox backgroundColor="lightOrange">
            Please note that as the result of recent changes to the voter file, the size of your
            audience has decreased from {userFriendlyNumber(oldAudienceSize)} to{" "}
            {userFriendlyNumber(size)}.
          </InfoBox>
          <br />
        </>
      );
    }

    let columnSpecs = [
      {
        field: "fieldType",
        headerName: "Data type",
        width: 125,
      },
      {
        field: "notAvailable",
        headerName: "# Not Available",
        valueFormatter: numberFormatter,
        width: 150,
      },
      {
        field: "alreadyPurchased",
        headerName: `# Already ${paymentNeeded ? "Purchased" : "Exported"}`,
        valueFormatter: numberFormatter,
        width: 150,
      },
      {
        field: "newToPurchase",
        headerName: `# New to ${paymentNeeded ? "Purchase" : "Export"}`,
        valueFormatter: numberFormatter,
        width: 150,
      },
    ];

    if (paymentNeeded) {
      columnSpecs = [
        ...columnSpecs,
        {
          field: "pricePerRecord",
          headerName: "Price per Record",
          valueFormatter: creditsFormatter,
          width: 150,
        },
        {
          field: "totalCost",
          headerName: "Total Cost",
          valueFormatter: creditsFormatter,
          width: 125,
        },
      ];
    }

    return (
      <>
        {sizeWarning}
        {summaryStatement}
        <br />
        <br />
        <DataGrid
          disableColumnFilter
          disableColumnMenu
          hideFooter
          columns={columnSpecs.map(c => ({ ...c, sortable: false }))}
          rows={costRows}
          rowSelection={false}
          sx={{
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          }}
        />
        <br />
        <br />
        {actionSection}
        <br />
        <br />
        <Button onClick={handleBackClick}>Back</Button>
      </>
    );
  };

  let mainContent;
  if (isPurchaseLoading) {
    mainContent = <Loading />;
  } else if (screenToDisplay === "start") {
    mainContent = renderStartScreen();
  } else if (screenToDisplay === "review") {
    if (isCreatePreviewExportLoading || !previewExport) {
      mainContent = <Loading />;
    } else {
      mainContent = renderReviewScreen();
    }
  }

  return mainContent;
};

export default AudienceCsvExportModalContent;
