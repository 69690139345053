import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

const useAudienceNameField = () => {
  const [audienceName, setAudienceName] = useState("");
  const [audienceNameError, setAudienceNameError] = useState();

  // Remove the error once it's no longer relevant.
  useEffect(() => {
    if (audienceName && audienceName.length > 0 && !!audienceNameError) {
      setAudienceNameError(null);
    }
  }, [audienceName, audienceNameError]);

  const audienceNameField = (
    <TextField
      fullWidth
      autoComplete="off"
      error={!!audienceNameError}
      helperText={audienceNameError ? audienceNameError : ""}
      inputProps={{ autoComplete: "off" }}
      label="Name your audience"
      margin="dense"
      name="audience-name"
      onChange={e => setAudienceName(e.target.value)}
      size="small"
      sx={{ marginBottom: "1rem" }}
      value={audienceName}
    />
  );

  const validateAudienceName = () => {
    if (audienceName.length === 0) {
      setAudienceNameError("This field is required.");
      return false;
    } else {
      setAudienceNameError(null);
      return true;
    }
  };

  const resetAudienceName = () => {
    setAudienceName("");
    setAudienceNameError(null);
  };

  return {
    audienceNameField,
    audienceName,
    validateAudienceName,
    setAudienceName,
    resetAudienceName,
  };
};

export default useAudienceNameField;
