import { Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { orderBy } from "lodash";
import { Link } from "react-router-dom";

import { formatDate, formatPercentage, userFriendlyCents, userFriendlyNumber } from "../helpers";
import useGetActiveOrg from "../hooks/useGetActiveOrg";
import { OneLineTypography } from "../styles";

import Loading from "./shared/Loading";

const SHOW_PAGINATION_THRESHOLD = 25;

const PurchasedEntityLink = ({ entity }) => {
  let display;
  switch (entity.__typename) {
    case "Audience": {
      display = (
        <span>
          CSV match: <Link to={`/audiences/${entity.id}`}>{entity.name}</Link>
        </span>
      );
      break;
    }
    case "CsvAudienceExport": {
      display = (
        <span>
          CSV audience export:{" "}
          <Link to={`/audiences/${entity.audienceId}`}>{entity.audienceName}</Link>
        </span>
      );
      break;
    }
    case "FacebookCustomAudienceExport": {
      display = (
        <span>
          FB custom audience export:{" "}
          <Link to={`/audiences/${entity.audienceId}`}>{entity.audienceName}</Link>
        </span>
      );
      break;
    }
    case "OutreachCampaign": {
      display = (
        <span>
          Campaign: <Link to={`/campaigns/${entity.id}`}>{entity.outreachCampaignName}</Link>
        </span>
      );
      break;
    }
    default: {
      return "Unknown";
    }
  }
  return (
    <OneLineTypography title={entity.audienceName || entity.outreachCampaignName} variant="body2">
      {display}
    </OneLineTypography>
  );
};

const CreditTransactions = () => {
  const { organization } = useGetActiveOrg();
  const { creditTransactions } = organization;

  if (!creditTransactions) {
    return <Loading />;
  }

  if (creditTransactions.length === 0) {
    return "No credit transactions yet.";
  }

  const numCreditsColumn = {
    field: "numCredits",
    headerName: "Credits",
    width: 100,
    valueFormatter: params => userFriendlyNumber(Math.abs(params.value)),
  };

  const createdAtColumn = {
    field: "createdAt",
    headerName: "On",
    valueFormatter: params => formatDate(params.value),
  };

  const createdByColumn = {
    field: "createdBy",
    headerName: "By",
    valueGetter: params => (params.row.transactionType !== "GRANT" ? params.value.firstName : "-"),
  };

  const fundColumns = [
    numCreditsColumn,
    {
      field: "amountPaidInCents",
      headerName: "Amount paid",
      width: 140,
      valueFormatter: params => (params.value === null ? "-" : userFriendlyCents(params.value)),
    },
    {
      field: "volumeDiscountPercentage",
      headerName: "Volume discount",
      width: 155,
      valueFormatter: params =>
        params.value === null ? "-" : formatPercentage({ value: params.value / 100 }),
    },
    createdAtColumn,
    createdByColumn,
  ];

  const grantColumns = [numCreditsColumn, createdAtColumn];

  const debitColumns = [
    numCreditsColumn,
    {
      field: "purchasedEntity",
      headerName: "Purchased",
      width: 400,
      renderCell: params => <PurchasedEntityLink entity={params.value} />,
    },
    createdAtColumn,
    createdByColumn,
  ];

  const creditTransactionsSorted = orderBy(creditTransactions, ["createdAt"], ["desc"]);
  const debitRows = creditTransactionsSorted.filter(t => t.transactionType === "DEBIT");
  const fundRows = creditTransactionsSorted.filter(t => t.transactionType === "FUND");
  const grantRows = creditTransactionsSorted.filter(t => t.transactionType === "GRANT");

  const TransactionTable = ({ title, columns, rows }) => {
    if (rows.length === 0) {
      return null;
    }
    const columnsWithNoSorting = columns.map(c => ({ ...c, sortable: false }));
    return (
      <>
        <Typography sx={{ marginTop: "2rem" }} variant="h4">
          {title}
        </Typography>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            disableColumnFilter
            disableColumnMenu
            columns={columnsWithNoSorting}
            hideFooter={rows.length < SHOW_PAGINATION_THRESHOLD}
            rows={rows}
            rowSelection={false}
            sx={{ marginTop: 5 }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Typography variant="h2">Credit transaction history</Typography>
        </Grid>
        <Grid item>
          <Link to="/credits/purchase">Purchase more credits</Link>
        </Grid>
      </Grid>
      <TransactionTable columns={debitColumns} rows={debitRows} title="Credits used:" />
      <TransactionTable columns={fundColumns} rows={fundRows} title="Credits purchased:" />
      <TransactionTable columns={grantColumns} rows={grantRows} title="Credits granted:" />
    </>
  );
};

export default CreditTransactions;
