// This file contains client-side-only constants. Constants shared between the frontend and backend
// should be sent over by the `get_constants` endpoint.
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";

// This one is duplicated on the backend
export const MAX_TEXT_LENGTH_STANDARD = 255;
export const MIN_PASSWORD_LENGTH = 8;

export const TOP_NAV_HEIGHT = 64;

export const NON_FILTER_FILTER_KEYS = ["id", "createdAt", "__typename"];

export const AUDIENCE_RELATIONSHIP_NAMES_WITH_REVERSE_NAMES = {
  // We don't want to include this one in the UI since it's not a "real" relationship.
  // audienceCopiedAndEditedFrom: "audienceIdsCopiedAndEditedTo",
  audienceFilteredFrom: "audienceIdsFilteredTo",
  audienceLookalikeCreatedFrom: "lookalikeAudienceIdsCreatedTo",
  audienceRegeneratedFrom: "audienceIdsRegeneratedTo",
};

export const AUDIENCE_RELATIONSHIP_NAME_DISPLAY = {
  audienceCopiedAndEditedFrom: "Copied and edited from",
  audienceIdsCopiedAndEditedTo: "Copied and edited to",
  audienceFilteredFrom: "Filtered from",
  audienceIdsFilteredTo: "Filtered to",
  audienceLookalikeCreatedFrom: "Lookalike generated from",
  lookalikeAudienceIdsCreatedTo: "Lookalikes generated",
  audienceRegeneratedFrom: "Regenerated from",
  audienceIdsRegeneratedTo: "Regenerated to",
};

export const GEO_TYPES = {
  states: "States",
  congressionalDistricts: "US House Districts",
  stateLegUpperDistricts: "State Senate Districts",
  stateLegLowerDistricts: "State House Districts",
  counties: "Counties",
};

export const HAS_DONOR_HISTORY = [
  { value: "true", label: "Has donation history" },
  { value: "false", label: "No donation history" },
];

export const SHOULD_SORT_ASCENDING = [
  { value: "true", label: "Ascending" },
  { value: "false", label: "Descending" },
];

export const DISTANCE_FROM_ZIP_DEFAULT = 0;
export const DISTANCE_FROM_ZIP_MIN = 0;
export const DISTANCE_FROM_ZIP_MAX = 100;
export const DISTANCE_FROM_ZIP_GEO_TYPE_OPTION = {
  value: "zip",
  label: "Distance from ZIP code",
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyBzhOkBHqiTBXXbJh9wCP-Xa5IkNY6eizQ";

// Keep up to date with the value of MIN_AUDIENCE_SIZE_FOR_LOOKALIKE on the backend.
export const VALID_AUDIENCES_FOR_LOOKALIKES_COPY =
  "To be eligible for lookalike expansion, an audience must be based on matching at least 1,000 " +
  "people to the voter file from an uploaded CSV. This includes audiences filtered from " +
  "CSV-matched audiences.";

export const CONTENT_GROUP_TYPES = {
  DigitalAd: {
    name: "Digital ad",
    icon: <AdsClickOutlinedIcon />,
    url: "/content/group/new/DigitalAd",
    platformLabelDisplay: "Platform",
    comingSoon: false,
  },
  TextMessage: {
    name: "Text message",
    icon: <SmsOutlinedIcon />,
    url: "/content/group/new/TextMessage",
    platformLabelDisplay: "Provider",
    comingSoon: true,
  },
  DirectMail: {
    name: "Direct mail",
    icon: <MailOutlinedIcon />,
    url: "/content/group/new/DirectMail",
    comingSoon: true,
  },
};

export const TEXT_VARIABLES = {
  first_name: "Their first name",
  zip: "Their ZIP code",
};

export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
