import { isEmpty, map, mapValues, sortBy } from "lodash";
import { useMemo } from "react";

import { useAppState } from "../../AppStateContext";
import { valueLabelListToLookupDict } from "../../helpers";
import HorizontalPieChart from "../shared/HorizontalPieChart";

const DEMO_CATEGORIES = {
  gender: { title: "Sex", constant: "GENDERS" },
  // Age group doesn't have a corresponding constants value, we just use the value in the
  // metadata key directly.
  age_group: { title: "Age group" },
  party: { title: "Political party", constant: "PARTIES" },
  race: { title: "Race", constant: "RACES" },
};

const ALWAYS_LAST = ["NONE", "IND"];

// Returns transformed metadata ready to be used by our HorizontalPieChart component, including
// the appropriate labels. For example:
// {
//   party: [
//     {key: 'DEM', label: 'Democratic', value: 125060},
//     {key: 'REP', label: 'Republican', value: 96477},
//     {key: 'IND', label: 'Other', value: 425381},
//   ],
//   age_group: [
//     {"key": "18-34", "label": "18-34", "value": 646918},
//     {"key": "35-54", "label": "35-54", "value": 0},
//     {"key": "55+", "label": "55+", "value": 0},
//   ],
//   ...
// }
const getTransformedMetadata = (metadata, constants) => {
  const newMetadata = {};
  if (!metadata || metadata.size === 0) {
    return newMetadata;
  }
  // Transform metadata from flat dicts to dicts nested by the demo category by splitting the keys
  // by "__".
  Object.keys(metadata).forEach(k => {
    if (!k.includes("__")) {
      return;
    }
    const [demo, category] = k.split("__");
    const value = metadata[k];
    if (!newMetadata[demo]) {
      newMetadata[demo] = {};
    }
    newMetadata[demo][category] = value;
  });
  // Get the appropriate constants data for each demographic category.
  const constantLookupDicts = Object.keys(DEMO_CATEGORIES).reduce((acc, demoCatKey) => {
    const constantKey = DEMO_CATEGORIES[demoCatKey].constant;
    if (constantKey) {
      acc[demoCatKey] = valueLabelListToLookupDict(constants[constantKey]);
    }
    return acc;
  }, {});
  // Transform the keys in those dicts into "labels" (i.e. what we want to show the user)
  // based on the constants data.
  const newMetadataWithLabels = mapValues(newMetadata, (demoCatVal, demoCatKey) =>
    sortBy(
      map(demoCatVal, (value, key) => {
        const constantsVal = constantLookupDicts[demoCatKey];
        return { key: key, label: constantsVal ? constantsVal[key] : key, value: value };
      }),
      // If the key is in ALWAYS_LAST, make it always last.
      item => [ALWAYS_LAST.includes(item.key) ? 1 : 0, item.label],
    ),
  );
  return newMetadataWithLabels;
};

const AudienceDemographics = ({ audience }) => {
  const { constants } = useAppState();
  const metadata = useMemo(
    () => getTransformedMetadata(audience.metadata, constants),
    [audience, constants],
  );
  if (isEmpty(metadata)) {
    return;
  }
  return (
    <>
      {Object.keys(DEMO_CATEGORIES).map(demo => (
        <HorizontalPieChart data={metadata[demo]} key={demo} title={DEMO_CATEGORIES[demo].title} />
      ))}
    </>
  );
};

export default AudienceDemographics;
