import { gql, useMutation } from "@apollo/client";
import { Typography, TextField, Box, Button, Grid } from "@mui/material";
import { map } from "lodash";
import { Fragment, useRef, useState } from "react";

import { CONTENT_GROUP_TYPES, TEXT_VARIABLES } from "../../constants";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import FileDropzone from "../shared/FileDropzone";
import Loading from "../shared/Loading";
import SharedModal from "../shared/SharedModal";
import SimpleTextButton from "../shared/SimpleTextButton";

const CreateContentVariationForm = ({
  formErrors,
  validateFieldIsPresent,
  setContentVariationData,
  contentVariationData,
  isFirstVariation = true,
  type,
}) => {
  const { organizationId } = useGetActiveOrg();
  const [selectedFile, setSelectedFile] = useState();
  const [isInsertVariableModalOpen, setIsInsertVariableModalOpen] = useState(false);

  const textRef = useRef();
  const [textCursorPosition, setTextCursorPosition] = useState(0);

  const attachmentType = type === "DirectMail" ? "Pdf" : "Image";
  const attachmentClass = `ContentComponent${attachmentType}`;

  const CREATE_CONTENT_COMPONENT_ATTACHMENT = gql`
    mutation ($input: ${attachmentClass}Input!) {
      create${attachmentClass}(input: $input) {
        id
        url
        temporarySignedUrl
      }
    }
  `;

  const [
    doCreateContentComponentAttachment,
    { loading: isCreateContentComponentAttachmentLoading },
  ] = useMutation(CREATE_CONTENT_COMPONENT_ATTACHMENT);

  const setVariationData = (name, value) => {
    setContentVariationData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSelectFile = file => {
    setSelectedFile(file);
    const input = { organizationId };
    if (type !== "DirectMail") {
      input.extension = file.name.split(".").pop();
    }
    doCreateContentComponentAttachment({
      variables: { input },
      onCompleted: data => {
        const { temporarySignedUrl, id } = data[`create${attachmentClass}`];

        fetch(temporarySignedUrl, {
          body: file,
          headers: { "Content-Type": file.type },
          method: "PUT",
        }).then(response => {
          if (response.status === 200) {
            setVariationData(`contentComponent${attachmentType}Id`, id);
            setVariationData("previewAttachmentUrl", URL.createObjectURL(file));
          }
        });
      },
    });
  };

  const onRemoveAttachment = () => {
    setSelectedFile(null);
    setVariationData("previewAttachmentUrl", null);
  };

  const insertVariable = variable => {
    const oldContent = contentVariationData.textContent;
    const newContent =
      oldContent.substring(0, textCursorPosition) +
      `{{ ${variable} }}` +
      oldContent.substring(textCursorPosition, oldContent.length);
    setVariationData("textContent", newContent);
    setIsInsertVariableModalOpen(false);
  };

  let fileChooser;
  if (
    isCreateContentComponentAttachmentLoading ||
    (selectedFile && !contentVariationData.previewAttachmentUrl)
  ) {
    fileChooser = <Loading />;
  } else if (contentVariationData.previewAttachmentUrl) {
    const removeButton = (
      <Box>
        <SimpleTextButton onClick={onRemoveAttachment}>remove</SimpleTextButton>
      </Box>
    );
    if (type === "DirectMail") {
      fileChooser = (
        <>
          TODO show PDF filename and icon here
          {removeButton}
        </>
      );
    } else {
      fileChooser = (
        <>
          <img
            alt=""
            src={contentVariationData.previewAttachmentUrl}
            style={{ maxWidth: "100%", height: "130px" }}
          />
          {removeButton}
        </>
      );
    }
  } else {
    const fileType = type === "DirectMail" ? "PDF" : "image";
    fileChooser = <FileDropzone fileType={fileType} onSelect={onSelectFile} />;
  }

  const insertVariableDisplay =
    type === "TextMessage" ? (
      <>
        <Button onClick={() => setIsInsertVariableModalOpen(true)} size="small">
          Insert variable
        </Button>
        <SharedModal
          isOpen={isInsertVariableModalOpen}
          setIsOpen={setIsInsertVariableModalOpen}
          title="Variables"
        >
          <>
            <Grid container>
              {map(TEXT_VARIABLES, (description, variable) => (
                <Fragment key={variable}>
                  <Grid item xs={6}>
                    <Button onClick={() => insertVariable(variable)} variant="text">
                      <code>{variable}</code>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ marginTop: "3.5px" }}>{description}</Box>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </>
        </SharedModal>
      </>
    ) : null;

  const typeName = CONTENT_GROUP_TYPES[type].name.toLowerCase();

  const attachmentLabel = type === "DirectMail" ? "Add your PDF" : "Add an image (optional)";

  const textInputSection =
    type !== "DirectMail" ? (
      <>
        <Grid container alignContent="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">Compose text</Typography>
          </Grid>
          <Grid item>{insertVariableDisplay}</Grid>
        </Grid>
        <TextField
          fullWidth
          multiline
          error={!!formErrors.contentVariationTextContent}
          helperText={
            formErrors.contentVariationTextContent ? formErrors.contentVariationTextContent : ""
          }
          inputRef={textRef}
          margin="dense"
          name="content-variation-text"
          onChange={e => {
            validateFieldIsPresent("contentVariationTextContent", e.target.value);
            setVariationData("textContent", e.target.value);
          }}
          onSelect={() => setTextCursorPosition(textRef.current.selectionStart)}
          rows={5}
          size="small"
          sx={{ marginBottom: "1rem" }}
          value={contentVariationData.textContent}
        />
      </>
    ) : null;

  return (
    <>
      <Typography variant="h4">
        Name your {isFirstVariation ? "first " : ""}
        {typeName} variation
      </Typography>
      {isFirstVariation ? (
        <Typography variant="subtitle2">You can create additional variations later.</Typography>
      ) : null}
      <TextField
        fullWidth
        error={!!formErrors.contentVariationName}
        helperText={formErrors.contentVariationName ? formErrors.contentVariationName : ""}
        margin="dense"
        name="content-variation-name"
        onChange={e => {
          setVariationData("name", e.target.value);
          validateFieldIsPresent("contentVariationName", e.target.value);
        }}
        size="small"
        sx={{ marginBottom: "1rem" }}
        value={contentVariationData.name}
      />
      {textInputSection}
      <Typography variant="h4">{attachmentLabel}</Typography>
      <Box sx={{ margin: "1rem 0" }}>{fileChooser}</Box>
    </>
  );
};

export default CreateContentVariationForm;
