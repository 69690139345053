import { gql, useMutation } from "@apollo/client";
import { Typography, TextField, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppStateDispatch } from "../../AppStateContext";
import { CONTENT_GROUP_TYPES } from "../../constants";
import { CORE_CONTENT_GROUP_FIELDS } from "../../fragments";
import { getInitialContentVariationData, validateContentVariationData } from "../../helpers";
import useCreateContentVariation from "../../hooks/useCreateContentVariation";
import useFormErrors from "../../hooks/useFormErrors";
import useGetActiveOrg from "../../hooks/useGetActiveOrg";
import Loading from "../shared/Loading";
import Tabs from "../shared/Tabs";
import TwoColumnLayoutWithPreview from "../shared/TwoColumnLayoutWithPreview";

import ContentGroupsHeader from "./ContentGroupsHeader";
import CreateContentVariationForm from "./CreateContentVariationForm";
import VariationPreviewForForm from "./VariationPreviewForForm";

const CREATE_CONTENT_GROUP = gql`
  ${CORE_CONTENT_GROUP_FIELDS}
  mutation ($input: ContentGroupInput!) {
    createContentGroup(input: $input) {
      ...CoreContentGroupFields
      organization {
        id
      }
    }
  }
`;

const CreateContentGroup = () => {
  const { type } = useParams();
  const dispatch = useAppStateDispatch();

  const [contentGroupName, setContentGroupName] = useState("");
  const [contentVariationData, setContentVariationData] = useState(
    getInitialContentVariationData(),
  );
  // We need a separate piece of state because we're going to be waiting on the refetch + dispatch.
  const [areRequestsAndDispatchLoading, setAreRequestsAndDispatchLoading] = useState(false);
  const { formErrors, validateFieldIsPresent } = useFormErrors();
  const navigate = useNavigate();
  const { organization } = useGetActiveOrg();
  const { createContentVariation } = useCreateContentVariation(type);

  const [doCreateContentGroupMutation, { loading: createContentGroupLoading }] =
    useMutation(CREATE_CONTENT_GROUP);

  // @todo combine with validation logic in CreateContentVariation
  const validateFields = () => {
    const groupNameValidates = validateFieldIsPresent("contentGroupName", contentGroupName);
    const variationValidates = validateContentVariationData({
      contentVariationData,
      type,
      validateFieldIsPresent,
    });
    return groupNameValidates && variationValidates;
  };

  const createContent = () => {
    if (!validateFields()) {
      return;
    }
    setAreRequestsAndDispatchLoading(true);
    doCreateContentGroupMutation({
      variables: {
        input: {
          organizationId: organization.id,
          name: contentGroupName,
          type,
        },
      },
      onCompleted: contentGroupData => {
        const newContentGroup = contentGroupData.createContentGroup;
        dispatch({
          type: "org-add-content-group",
          contentGroup: newContentGroup,
        });
        createContentVariation({
          contentVariationData,
          contentGroupRawId: newContentGroup.id,
          onCreate: () => {
            toast("Your content was successfully created.", { type: "success" });
            navigate(`/content/group/${newContentGroup.typedId}`);
          },
          onError: () => setAreRequestsAndDispatchLoading(false),
        });
      },
      onError: () => setAreRequestsAndDispatchLoading(false),
    });
  };

  if (createContentGroupLoading || areRequestsAndDispatchLoading) {
    return <Loading />;
  }

  const mainContent = (
    <>
      <Typography variant="h4">Name your content</Typography>
      <TextField
        fullWidth
        error={!!formErrors.contentGroupName}
        helperText={formErrors.contentGroupName ? formErrors.contentGroupName : ""}
        margin="dense"
        name="content-group-name"
        onChange={e => {
          setContentGroupName(e.target.value);
          validateFieldIsPresent("contentGroupName", e.target.value);
        }}
        size="small"
        sx={{ marginBottom: "1rem" }}
        value={contentGroupName}
      />
      <CreateContentVariationForm
        {...{
          formErrors,
          validateFieldIsPresent,
          setContentVariationData,
          contentVariationData,
          type,
        }}
      />
      <Button disabled={CONTENT_GROUP_TYPES[type].comingSoon} onClick={createContent}>
        Create content
      </Button>
    </>
  );

  // @todo-content we'll probably need a separate one for digital ads, but this is fine for now.
  const previewContent = (
    <VariationPreviewForForm type={type} variationData={contentVariationData} />
  );

  return (
    <>
      <ContentGroupsHeader headerText="Add new content" showNewContentGroupButton={false} />
      <Tabs tabs={Object.values(CONTENT_GROUP_TYPES)} />
      <TwoColumnLayoutWithPreview mainContent={mainContent} previewContent={previewContent} />
    </>
  );
};

export default CreateContentGroup;
