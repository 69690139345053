import { useEffect, useMemo } from "react";

import { useAppState, useAppStateDispatch } from "../AppStateContext";

const useGetActiveOrg = () => {
  const dispatch = useAppStateDispatch();
  const { organizations, user, activeOrgId } = useAppState();

  // Get the org based on activeOrgId.
  const activeOrg = useMemo(
    () => activeOrgId && organizations[activeOrgId],
    [activeOrgId, organizations],
  );

  // If that doesn't work, use the first org in the user's org list as the default.
  const organization = activeOrg || organizations[user.organizations[0].id];
  const organizationId = organization.id;

  // Clear activeOrgId in local storage if the user was removed from the org, or logged out
  // and logged back in as a different user after setting an activeOrgId with the first user
  // that the second user isn't in.
  useEffect(() => {
    if (activeOrgId && !activeOrg) {
      dispatch({ type: "active-org-id-clear" });
    }
  }, [activeOrgId, activeOrg, dispatch]);

  return { organization, organizationId };
};

export default useGetActiveOrg;
