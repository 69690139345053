import { Box } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useAppState, useAppStateDispatch } from "../AppStateContext";
import useGetActiveOrg from "../hooks/useGetActiveOrg";

import DropdownSelectOne from "./shared/DropdownSelectOne";

const OrgPicker = () => {
  const dispatch = useAppStateDispatch();
  const navigate = useNavigate();
  const { organization } = useGetActiveOrg();
  const { activeOrgId, organizations } = useAppState();

  const organizationOptions = useMemo(
    () =>
      organizations &&
      Object.values(organizations).map(org => ({ value: org.id, label: org.name })),
    [organizations],
  );

  if (!organizationOptions || organizationOptions.length <= 1) {
    return null;
  }

  const selectOrg = activeOrgId => {
    dispatch({ type: "active-org-id-set", activeOrgId });
    navigate("/");
  };

  return (
    <Box>
      Select org:{" "}
      <DropdownSelectOne
        fullWidth={false}
        onChange={selectOrg}
        options={organizationOptions}
        value={activeOrgId || (organization && organization.id)}
      />
    </Box>
  );
};

export default OrgPicker;
