import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { useAppState, useAppStateDispatch } from "../AppStateContext";
import { CORE_FACEBOOK_AD_ACCOUNT_FIELDS } from "../fragments";

// This is defined in the app dashboard. It specifies which permissions we're going to request from
// the user.
const LOGIN_CONFIG_ID = 862753089333151;

// const TEST_LOGIN_CONFIG_ID = 1245010326637892; // this is for the test app

const SAVE_USER_CREDENTIAL = gql`
  ${CORE_FACEBOOK_AD_ACCOUNT_FIELDS}
  mutation ($input: UserCredentialInput!) {
    saveUserCredential(input: $input) {
      id
      facebookAdAccounts {
        ...CoreFacebookAdAccountFields
      }
    }
  }
`;

const facebookInit = ({ appId, version }) => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId, // test app ID = 418294597587175
      cookie: true,
      xfbml: true,
      version,
    });

    window.FB.AppEvents.logPageView();
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

export const useFacebook = () => {
  const { constants } = useAppState();
  const { FACEBOOK_APP_ID, FACEBOOK_API_VERSION } = constants;
  const dispatch = useAppStateDispatch();

  const [doSaveToken] = useMutation(SAVE_USER_CREDENTIAL);

  const facebookLogin = callback => {
    window.FB.login(
      response => {
        if (!response.authResponse) {
          toast("Error logging into Facebook. Please try again.", { type: "error" });
          dispatch({ type: "did-facebook-login-fail-update", didFacebookLoginFail: true });
        } else {
          doSaveToken({
            variables: { input: { type: "FACEBOOK", secret: response.authResponse.accessToken } },
            onCompleted: callback,
          });
        }
      },
      {
        config_id: LOGIN_CONFIG_ID,
      },
    );
  };

  return {
    facebookInit: () => facebookInit({ appId: FACEBOOK_APP_ID, version: FACEBOOK_API_VERSION }),
    facebookLogin,
  };
};

export const isFacebookLoggedIn = () => {
  if (!window.FB) {
    // This shouldn't happen -- facebookInit should have already run at this point -- but if it
    // does happen, let's just bail here.
    // TODO: more robust handling of this situation?
    return false;
  }
  let status;
  window.FB.getLoginStatus(response => {
    ({ status } = response);
  });
  return status === "connected";
};
