import pluralize from "pluralize";
import { useState } from "react";
import { Link } from "react-router-dom";

import SharedModal from "../shared/SharedModal";
import TextLabel from "../shared/TextLabel";

const CampaignsUsedIn = ({ audienceOrContentGroup }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const campaigns = audienceOrContentGroup.outreachCampaigns;
  if (!campaigns || !campaigns.length) {
    return null;
  }

  let content;
  if (campaigns.length > 1) {
    content = (
      <>
        <Link onClick={() => setIsModalOpen(true)}>
          {campaigns.length} {pluralize("campaign", campaigns.length)}
        </Link>
        <SharedModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title="Campaigns">
          <ul style={{ margin: 0 }}>
            {campaigns.map(c => (
              <li key={`campaign-${c.id}`}>
                <Link to={`/campaigns/${c.id}`}>{c.name}</Link>
              </li>
            ))}
          </ul>
        </SharedModal>
      </>
    );
  } else {
    content = <Link to={`/campaigns/${campaigns[0].id}`}>{campaigns[0].name}</Link>;
  }

  return (
    <>
      <TextLabel>Used in: </TextLabel>
      {content}
    </>
  );
};

export default CampaignsUsedIn;
